import { CategoryTreeListItem } from '../shared/models/category.model';
import { TreeListItem } from '../shared/models/tree-list';

export class ArrayHelper{
    //TuanNA2: Đang tạm viết helper cho category, cần chuyển về generic type
    //#region Các helper liên quan đến tree list
    //Convert mảng sang cấu trúc tree
    public static arrayToTreeList<T>(input: TreeListItem<T>[]){
        //let input = [...inputList];
        let resultTree: TreeListItem<T>[] = [];
        const idMapping = input.reduce((acc, el, i) => {
            acc[el.id] = i;
            return acc;
        }, {});
        
        //build parent-child structure
        input.forEach(el => {
            if (el.parentId === 0) {
                //el.treeLevel = 1;
                resultTree.push(el);
                return;
            }
            else{
                const parentEl = input[idMapping[el.parentId]] as TreeListItem<T>;
                if(parentEl != null){
                    //el.treeLevel = parentEl.treeLevel + 1; 
                    parentEl.children = [...(parentEl.children || []), el] as T[];
                }
                else {
                    //el.treeLevel = 1;
                    resultTree.push(el);
                    return;
                }
            }
        });
        //build tree level
        resultTree.forEach(el => {
            this.buildTreeLevelRecursion(el, 1);
        });
        return resultTree;
    }

    //Convert cấu trúc tree thành mảng theo order của parent-child
    public static treeListToTreeOrderedArray<T>(list: TreeListItem<T>[]){
        let result: TreeListItem<T>[] = [];
        list.forEach(el => {
            this.flattenRecursion(el, result);
        });
        //result.map(x=> x.children = []);
        return result;
    }

    //Convert mảng thành mảng theo order của parent-child
    public static arrayToTreeOrderedArray<T>(inputList: TreeListItem<T>[]){
        return this.treeListToTreeOrderedArray(this.arrayToTreeList(inputList));
    }

    private static buildTreeLevelRecursion(el, level: number)
    {
        el.treeLevel = level;
        this.extractChildren(el).forEach(child => {
            this.buildTreeLevelRecursion(child, el.treeLevel + 1);
        });
    }

    private static flattenRecursion<T>(el, result: TreeListItem<T>[])
    {
        result.push(el);
        this.extractChildren(el).forEach(child => {
            this.flattenRecursion(child, result);
        });
    }
    private static extractChildren = <T>(x: TreeListItem<T>) => {
        if(x.children == undefined || x.children == null || x.children.length == 0){
            return [];
        }
        else{
            return x.children as T[];
        };
    }
    //#endregion
}