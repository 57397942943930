<div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="loading">
            <ngx-spinner name="ssoLoginSpinner" [fullScreen]="false" type="square-jelly-box" size="medium" bdColor="rgba(255,255,255,0)" color="#E81E66"></ngx-spinner>   
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-8" [class]="showError? 'd-block' : 'd-none'">
                <div class="card">
                    <div class="card-body p-3 clearfix">
                      <i class="fa fa-sign-in bg-info p-3 font-2x2 mr-3 float-left" style="font-size: 4rem;"></i>
                      <div class="text-muted text-uppercase font-weight-bold font-xs">{{ errorMsg }}</div>
                      <div class="text-muted font-weight-bold font-xs">Trade Id: {{errorTradeId}}</div>
                      <button 
                        type="button" 
                        class="btn btn-square btn-info try-again font-weight-bold"
                        (click)="tryAgain()">
                        {{'Common.TryAgain' | translate}}
                        </button>
                      <!-- <button 
                        type="button" 
                        class="btn" 
                        style="margin-right: 10px;" 
                        (click)="tryAgain()">
                        {{'Common.TryAgain' | translate}}
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
      </div>
    </main>
  </div>