import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StringHelper } from "../../helper";
import { BaseListFilter } from "../../shared/models/base-list-filter";
import { FilterPromotionForBoxModel, FilterPromotionModel, PromotionForCreationModel, PromotionForSetHot, PromotionForUpdateModel, PromotionInListForBoxModel, PromotionInListModel, PromotionModel } from "../../shared/models/promotion.model";

@Injectable({
    providedIn: 'root'
})
export class PromotionService {
    private restApi: string;//T-TEMP
    constructor(
        private http: HttpClient, 
        @Inject('PROMOTION_URL') promotionUrl: string
    ) {
        this.restApi = `${promotionUrl}promotions`;
    }

    filter(request: FilterPromotionModel): Observable<BaseListFilter<PromotionInListModel>> {
        let parameters = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
                let value = request[key];
                parameters = parameters.set(key, value);
            }
            else{
                if (Array.isArray(request[key])) {
                    request[key].forEach(element => {
                        parameters = parameters.append(key, element);
                  });
                }
            }
        }
        return this.http.get<BaseListFilter<PromotionInListModel>>(this.restApi, { params: parameters });
    }

    filterForSetPromotionBox(request: FilterPromotionModel): Observable<BaseListFilter<PromotionInListForBoxModel>> {
        let parameters = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
                let value = request[key];
                parameters = parameters.set(key, value);
            }
            else{
                if (Array.isArray(request[key])) {
                    request[key].forEach(element => {
                        parameters = parameters.append(key, element);
                  });
                }
            }
        }
        return this.http.get<BaseListFilter<PromotionInListForBoxModel>>(`${this.restApi}/forbox`, { params: parameters });
    }

    getDetail(promotionId: string): Observable<PromotionModel> {
        return this.http.get<PromotionModel>(`${this.restApi}/${promotionId}`);
    }

    create(request: PromotionForCreationModel): Observable<string> {
        return this.http.post<string>(this.restApi, request);
    }

    update(request: PromotionForUpdateModel): Observable<string> {
        return this.http.put<string>(`${this.restApi}/${request.id}`, request);
    }

    setIsHot(request: PromotionForSetHot[]): Observable<string> {
        return this.http.post<string>(`${this.restApi}/set-hot`, request);
    }
}
