import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DistrictInListModel } from '../../shared/models/district.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {
  private restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('COMMON_URL') commonUrl: string
  ) 
  {
      this.restApi = `${commonUrl}districts`;
  }

  getAll(cityId: Number = 0): Observable<DistrictInListModel[]> {
    return this.http.get<DistrictInListModel[]>(`${this.restApi}/city/${cityId}`);
  }
}
