import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-language',
  styles: ['app-select-language { width:100%; }'],
  template: `
    <select class="form-control" (change)="changeLanguage($event)">
      <option
        *ngFor="let lang of langsDict"
        [value]="lang.code"
        [attr.selected]="lang.code === currentLang ? '' : null"
      >{{lang.name}}</option>
    </select>
  `,
})
export class SelectLanguageComponent {
  langsDict: LanguageDictionary[] = [];
  currentLang: string;

  constructor(private translate: TranslateService, private router: Router, private activeRoute: ActivatedRoute) {
    const langs = this.translate.getLangs();
    langs.forEach((item, idx) => {
      this.langsDict.push({ code: item, name: this.namedLang(item) })
    });
    this.currentLang = this.translate.currentLang;
  }

  changeLanguage($event: any) {
    let selectedLanguage = $event.target.value;
    this.translate.use(selectedLanguage);
    localStorage.setItem("lan",selectedLanguage);
  }

  namedLang(code: string): string {
    let name: string;
    switch (code) {
      case 'vi-VN': name = 'Vietnamese'; break;
      case 'en': name = 'English'; break;
      case 'th': name = 'Thailand'; break;
      case 'es': name = 'Spain'; break;
      case 'id': name = 'Indonesian'; break;
    }
    return name;
  }
}


export interface LanguageDictionary {
  code: string;
  name: string;
}