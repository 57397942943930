import { TranslateService } from '@ngx-translate/core';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as CustomEditor from '../../plugins/ckeditor/build-folder/ckeditor';
export class Constants {
    public static readonly urlPattern = /^(http(s)?:\/\/.)?(www\.)?(\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    public static readonly emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static readonly whatsAppPattern = /^(?=(?:[+ -]*[0-9][+ -]*){11,12}$)\+(?:[0-9]+[ -]?)+[0-9]$/;
    public static readonly slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    public static readonly yearPattern = /^(18|19|20|21)\d{2}$/;

    public static readonly identityCurrentUserKey: string = 'currentUser';
    public static readonly storageDomainKey: string = 'domains';
    public static readonly storageCustomerKey: string = 'customers';
    public static readonly storageThemeKey: string = 'themes';

    public static readonly storageFileDownloadHost: string = 'downloadHost';


    public static readonly settingSiteKey: string = 'settingsite';
    public static readonly settingUploadKey: string = 'settingupload';

    //public static readonly ckEditorClassic = ClassicEditor;
    public static readonly customCKEditor = CustomEditor;
    public static readonly editorConfig = {
        extraPlugins:
          'dvs.image',
        toolbar: {
            items: [
                'bold',
                'italic',
                'underline',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'indent',
                'outdent',
                '|',
                'imageUpload',
                'blockQuote',
                'insertTable',
                'undo',
                'redo',
                'dvs.image',
            ]
        },
        image: {
            toolbar: [
                'imageStyle:full',
                'imageStyle:side',
                '|',
                'imageTextAlternative'
            ]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells'
            ]
        },
        // This value must be kept in sync with the language defined in webpack.config.js.
        language: 'en'
    };
}
