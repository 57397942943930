import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorUpdateModel, AuthorListModel, AuthorDetailModel } from '../../shared/models/author.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PermissionDetailModels, PermissionTreeListItem, PermissionUpdateModel } from '../../shared/models/permission.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionService{

    permissionRestApi: string;
    permissionDetalRestApi: string;

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.permissionRestApi = `${commonUrl}permissions`;
        this.permissionDetalRestApi = `${commonUrl}permissiondetails`;
    }

    getList(): Observable<PermissionTreeListItem[]> {
        return this.http.get<PermissionTreeListItem[]>(this.permissionRestApi);
    }

    applyPemission(model: PermissionUpdateModel){
        return this.http.post(this.permissionDetalRestApi, model);
    }

    getUserPermissions(userId: number){
        return this.http.get<PermissionDetailModels[]>(`${this.permissionDetalRestApi}/${userId}`);
    }
}