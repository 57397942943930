export class BenefitTypeEnum {
    constructor(id: number, value: string) {
        this.id = id;
        this.name = value;
    }

    public id: number;
    public name: string;

    public static All: BenefitTypeEnum = new BenefitTypeEnum(-1, " -- Tất cả -- ");
    public static Cash: BenefitTypeEnum = new BenefitTypeEnum(1, "Tiền mặt");
    public static Installment: BenefitTypeEnum = new BenefitTypeEnum(2, "Trả góp");
    public static Gift: BenefitTypeEnum = new BenefitTypeEnum(3, "Quà tặng");

    public static BenefitTypes: BenefitTypeEnum[] = [BenefitTypeEnum.All, BenefitTypeEnum.Cash, BenefitTypeEnum.Installment, BenefitTypeEnum.Gift];

    public static GetById(id: number): BenefitTypeEnum {
        return this.BenefitTypes.find(x => x.id == id);
    }
}