import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { FilterBenefitModel, BenefitInListModel, BenefitCreateModel, BenefitUpdatedResponseModel } from "../../shared/models/benefit.model";

@Injectable({
    providedIn: 'root'
})
export class BenefitService {
    private restApi: string;
    constructor(
        private http: HttpClient, 
        @Inject('PROMOTION_URL') promotionUrl: string
    ) {
        this.restApi = `${promotionUrl}benefits`;
    }

    getAll(): Observable<BenefitInListModel[]> {
        return this.http.get<BenefitInListModel[]>(`${this.restApi}`);
    }

    create(request: BenefitCreateModel): Observable<number> {
        return this.http.post<number>(`${this.restApi}`, request);
    }

    update(id: number, request: BenefitCreateModel): Observable<BenefitUpdatedResponseModel> {
        return this.http.put<BenefitUpdatedResponseModel>(`${this.restApi}/${id}`, request);
    }
}
