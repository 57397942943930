export class StringHelper {
    public static isNullOrEmpty(value: string){
        return value === ''|| value === null;
    }
    // public static splitToArray(value: string, splitChar: string){

    // }
    public static isNumber(str: string): boolean {
        if (typeof str !== 'string') {
          return false;
        }
      
        if (str.trim() === '') {
          return false;
        }
      
        return !Number.isNaN(Number(str));
      }
}