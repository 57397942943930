import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuComponent } from 'ngx-contextmenu';

@Component({
  selector: 'storage-option-menus',
  templateUrl: './option-menus.component.html',
  styleUrls: ['./option-menus.component.scss']
})
export class OptionMenusComponent implements OnInit {
  @ViewChild('basicMenu') contextMenu: ContextMenuComponent;
  @Output('createDirectory') createDirectory: EventEmitter<any> = new EventEmitter<any>();
  @Output('updateDirectory') updateDirectory: EventEmitter<any> = new EventEmitter<any>();
  @Output('deleteDirectory') deleteDirectory: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private translate: TranslateService
  ){

  }
  ngOnInit(): void {
  }
}
