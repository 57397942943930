import { Observable, of } from "rxjs";
import { OrderDirection } from "../core/enums/order-direction.enum";
import { KeyValuePair } from "../shared/models/key-value.model";

export class UtilityHelper {
  public static toInt(value: any, defaultVal: number, radix: number = 10) {
    const parsed = parseInt(value, radix);
    if (isNaN(parsed)) { return defaultVal; }
    return parsed;
  }

  public static toFloat(value: any, defaultVal: number) {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) { return defaultVal; }
    return parsed;
  }

  public static stringToUrl(input: string) {
    let url = '';
    if (input == undefined || input == null || input == "") {
      return '';
    }
    else {
      url = input.trim();
    }
    var matches = url.match(/[^\x00-\x7F]+|[a-zA-Z0-9]+/g);
    url = "";
    for (var i = 0; i < matches.length; i++) {
      if (url == "") {
        url = matches[i];
      }
      else {
        url = url + "-" + matches[i];
      }
    }
    return url.toLowerCase().trim();
  }

  public static listKeyValueFromNumberRange(from: number, to: number, orderDirection: OrderDirection = OrderDirection.Desc): Observable<KeyValuePair[]> {
    if (from > to) {
      let temp = from;
      from = to;
      to = temp;
    }

    let result: KeyValuePair[] = [];
    for (let index = from; index <= to; index++) {
      result.push({ key: index, value: index });
    }
    if (orderDirection == OrderDirection.Desc) {
      return of(result.reverse());
    }
    return of(result);
  }

  public static generateKeyPairValueToDropdown<T>(arrayValue: T[], key: string = 'key', value: string = 'value', defaultSelect: string = '-- select --'): KeyValuePair[] {

    var options = arrayValue.map(x => {
      return new KeyValuePair({ key: x[key], value: x[value] });
    });

    options.unshift(new KeyValuePair({ key: "0", value: defaultSelect }));

    return options;
  }

  public static deepClone(obj){
    if( typeof obj != "object" || obj === null) return obj;
    
    const newObj = Array.isArray(obj) ? [] : {};
    for(let key in obj){
      const value = obj[key];
      //đệ quy cho các nested object | array
      newObj[key] = this.deepClone(value);
    }
    return newObj;
  }
}