import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActiveStatus } from '../enums/active-status.enum';
import { ModelInListFilter } from '../../shared/models/model.model';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) {
    this.restApi = `${this.carInfoUrl}models`;
  }

  getListByBrand(brandId: number, activeStatus: ActiveStatus): Observable<ModelInListFilter[]> {
    let queryParams: string = null;
    queryParams = `?status=${activeStatus}`;
    return this.http.get<ModelInListFilter[]>(`${this.restApi}/brand/${brandId}${queryParams}`);
  }

  getListForCRUDPromotion(brandId: number, activeStatus: ActiveStatus): Observable<ModelInListFilter[]> {
    let params = new HttpParams();
    params = params.append('brandId', `${brandId}`);
    params = params.append('status', `${activeStatus}`);
    return this.http.get<ModelInListFilter[]>(`${this.restApi}/for-crud-promotion?${params}`);
  }
}
