import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransmissionInListModel } from '../../shared/models/car-info/transmission.model';
import { ActiveStatus } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService {
  restApi: string;

  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') carInfoUrl: string
  ) {
    this.restApi = `${carInfoUrl}transmissions`;
  }

  getAll(status: ActiveStatus = ActiveStatus.All): Observable<TransmissionInListModel[]> {
    let params = new HttpParams();
    if(status != null){
      params = params.append('status', `${status}`);
    }
    return this.http.get<TransmissionInListModel[]>(`${this.restApi}`, {params:  params});
  }
}
