import { ActiveStatus } from '../enums/active-status.enum';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { CategoryInListModel, CategoryRequestUpdateModel, CategoryDetailModel, CategoryTreeListItem } from "../../shared/models/category.model";
import { ResponseData } from '../../shared/models/responsedata';
import { CategorySort } from '../enums/category.enum';
import { OrderDirection } from '../enums/order-direction.enum';
import { map } from 'rxjs/operators';
import { SiteManagerModel, SettingSite } from '../../shared/models/site-manager.model';

@Injectable({ providedIn: 'root' })
export class SiteManagerService {
    public siteSettings: SiteManagerModel;
    restApi: string;

    frontEndDomain: string = null;

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) 
    {
        this.siteSettings = new SiteManagerModel();
        this.restApi = `${commonUrl}sitemanagers`;
        //this.frontEndDomain = '';
    }


    public get authorDomail(){
        return `${this.frontEndDomain}/author/`;
    }

    public get categoryDomail(){
        return `${this.frontEndDomain}/category/`;
    }
    
    public get newCarDomain(){
        return `${this.frontEndDomain}/khuyenmai/`; //T-TEMP
    }

    getSiteConfiguration(siteId: number){
        this.http.get<SiteManagerModel>(`${this.restApi}/${siteId}`)
            .subscribe(res => {
                this.siteSettings = res as SiteManagerModel;
                this.frontEndDomain = this.siteSettings?.settingSite?.domainName || null;
            });
    }
}