<li *ngIf="!!_item"
    class="storage-treeview-item ui-unselectable " 
    [ngClass]="{
        'storage-treeview-item-selected': _item.id == _selectedDirectory?.id, 
        'storage-treeview-item-active': _item.id == _selectedDirectory?.id,
        'storage-treeview-item-list-expanded': _item.isExpanded
    }"
    role="treeitem" 
    aria-describedby="shielddy" 
    aria-expanded="true"
    >
    <div class="storage-treeview-item-content" style="white-space: nowrap">
        <span 
            *ngIf="_item.hasSubDirectories; else noSubDirectory"
            class="storage-treeview-item-toggle"
            [attr.subDirectoriesLoaded]="false"
            (click)="toggleSubFolder(_item, $event)">
            <span 
                class="storage-treeview-item-toggle-expanded"
                [ngClass]="{
                    'storage-treeview-item-toggle-expanded': _item.isExpanded,
                    'storage-treeview-item-toggle-collapsed': !_item.isExpanded
                }"
                >
            </span>
        </span>
        <ng-template #noSubDirectory>
            <span class="storage-treeview-item-toggle" style="visibility: hidden;">
                <span class="storage-treeview-item-toggle-collapsed"></span>
            </span>
        </ng-template>
        <span class="storage-treeview-item-text" style="white-space: nowrap" (click)="select.emit(_item)" (contextmenu)="onContextMenu($event, item)">
            <span class="storage-treeview-item-icon fa fa-folder"></span>
            <span style="padding-top: 2px;">{{ _item.name }}</span>
        </span>
    </div>
    <ul *ngIf="_item.hasSubDirectories"
        class="storage-treeview-list storage-treeview-item-list" 
        [ngClass]="{ 
            'd-block': _item.isExpanded,
            'd-none': !_item.isExpanded
        }"
        role="group">
        <storage-tree-list-item 
            *ngFor="let subDir of _item.subDirectories" 
            [item]="subDir"
            [selectedDirectory]="_selectedDirectory"
            (select)="select.emit($event)"
            (delete)="onDeleteSubFolder($event)">
        </storage-tree-list-item>
    </ul>
</li>

<ng-template #tmlUpdateDirectory>
    <storage-update-folder 
        [directoryToUpdate]="directoryToUpdate" 
        (saveResult) = "onDirectorySaved($event)"
        (closeModal) = "onCloseUpdateDirectoryModal()">
    </storage-update-folder>
</ng-template>

<storage-option-menus 
    (createDirectory)="handleCreateDirectoryEvent(tmlUpdateDirectory)"
    (updateDirectory)="handleUpdateDirectoryEvent(tmlUpdateDirectory)"
    (deleteDirectory)="handleDeleteDirectoryEvent()"
>
</storage-option-menus>