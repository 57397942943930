import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('NEWCARS_URL') private baseUrl: string
  ) { baseUrl
    this.restApi = `${this.baseUrl}caching`;
  }

  flushAllNewCar(): Observable<any>{
    return this.http.get(`${this.restApi}/flush`);
  }
}
