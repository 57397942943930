import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../helper';
import { ContentAngleFilter, ContentAngleInList } from '../../shared/models/content-angle.model';

@Injectable({
  providedIn: 'root'
})
export class ContentAngleService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('COMMON_URL') commonUrl: string
  ) 
  {
    this.restApi = `${commonUrl}content-angles`;
  }

  getListByFilter(filter: ContentAngleFilter = null): Observable<ContentAngleInList[]> {
    let params = new HttpParams();
    for (let key in filter) {
      if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
        
        params = params.append(key, filter[key]);
      }
    }
    return this.http.get<ContentAngleInList[]>(`${this.restApi}`, {params:  params});
  }
}
