import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StringHelper } from '../../helper';
import { PromotionPageDetail, PromotionPageListFilter, PromotionPageUpdateModel } from '../../shared/models/promotion-page.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionPageService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) 
  { 
    this.restApi = `${this.carInfoUrl}newcar-pages`;
  }

  getList(filter: PromotionPageListFilter): Observable<PromotionPageListFilter> {
    let params = new HttpParams();
    for (let key in filter) {
      if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
        params = params.append(key, filter[key]);
      }
    }
    return this.http.get<PromotionPageListFilter>(`${this.restApi}`, {params:  params});
  }

  create(model: PromotionPageUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  getDetail(id: number|string ): Observable<PromotionPageDetail> {
    return this.http.get<PromotionPageDetail>(`${this.restApi}/${id}`);
  }

  update(model: PromotionPageUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }
}
