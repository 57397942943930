import { UploadManagerComponent } from './modules/file-manager/upload-manager/uploadmanager';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './modules/category/category.component';
import { NewsComponent } from './modules/news/news.component';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { UserComponent } from './user/user.component';
import { LoginSsoComponent } from './login/login-sso.component';
import { FileManagerComponent } from './modules/file-manager/file-manager/filemanager';
import { NavigationService } from './core/services';
import { AuthGuard } from './core/auth/auth.guard';
import { LoginExternalComponent } from './login/login-external/login-external.component';


export const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: DefaultLayoutComponent, pathMatch: 'full' },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: NavigationService.specStructure, loadChildren: () => import('./modules/car-spec-structure/car-spec-structure.module').then(m => m.CarSpecStructureModule)
      },
      {
        path: NavigationService.carSpec, loadChildren: () => import('./modules/car-spec/car-spec.module').then(m => m.CarSpecModule)
      },
      {
        path: NavigationService.newCar, loadChildren: () => import('./modules/new-car/new-car.module').then(m => m.NewCarModule)
      },
      {
        path: NavigationService.promotion, loadChildren: () => import('./modules/promotions/promotion.module').then(m => m.PromotionModule)
      },
      {
        path: NavigationService.newcarPage, loadChildren: () => import('./modules/promotion-page/promotion-page.module').then(m => m.PromotionPageModule)
      },
      {
        path: NavigationService.financePromo, loadChildren: () => import('./modules/finance-promo/finance-promo.module').then(m => m.FinancePromoModule)
      },
      {
        path: NavigationService.salon, loadChildren: () => import('./modules/salon/salon.module').then(m => m.SalonModule)
      },
      {
        path: NavigationService.managerCategory, loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule)
      },
      {
        path: NavigationService.managerReview, loadChildren: () => import('./modules/review/review.module').then(m => m.ReviewModule)
      },
      {
        path: NavigationService.managerAuthor, loadChildren: () => import('./modules/author/author.module').then(m => m.AuthorModule)
      },
      {
        path: NavigationService.managerNews, loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule)
      },
      {
        path: NavigationService.managerArticleSource, loadChildren: () => import('./modules/article-source/article-source.module').then(m => m.ArticleSourceModule)
      },
      {
        path: NavigationService.managerUser, canActivate: [AuthGuard], loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: NavigationService.carInfo, canActivate: [AuthGuard], loadChildren: () => import('./modules/car-info/car-info.module').then(m => m.CarInfoModule)
      },
      {
        path: NavigationService.variant, canActivate: [AuthGuard], loadChildren: () => import('./modules/variant/variant.module').then(m => m.VariantModule)
      },
      {
        path: NavigationService.segments, canActivate: [AuthGuard], loadChildren: () => import('./modules/segments/segments.module').then(m => m.SegmentsModule)
      },
      {
        path: NavigationService.system, canActivate: [AuthGuard], loadChildren: () => import('./modules/system/system.module').then(m => m.SystemModule)
      },
      {
        path: NavigationService.color, canActivate: [AuthGuard], loadChildren: () => import('./modules/color/color.module').then(m => m.ColorModule)
      },
      {
        path: NavigationService.filemanager, canActivate: [AuthGuard], loadChildren: () => import('./modules/file-manager/file-manager.module').then(m => m.FileManagerModule)
      },
      //================== PLUGINS ======================
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: 'file-manager', component: FileManagerComponent, data: { title: 'Filemanager' } },
  { path: NavigationService.loginRoute, component: LoginComponent, data: { title: 'Login Page' } },
  { path: NavigationService.loginSSORoute, component: LoginSsoComponent, data: { title: 'Login SSO Page' } },
  { path: NavigationService.loginExternalRoute, component: LoginExternalComponent, data: { title: 'Login External Page' } },
  { path: NavigationService.notfoundLink, component: NotfoundComponent, data: { title: 'Page 404' } },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// export const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'dashboard',
//     pathMatch: 'full',
//   },
//   {
//     path: '404',
//     component: P404Component,
//     data: {
//       title: 'Page 404'
//     }
//   },
//   {
//     path: '500',
//     component: P500Component,
//     data: {
//       title: 'Page 500'
//     }
//   },
//   {
//     path: 'login',
//     component: LoginComponent,
//     data: {
//       title: 'Login Page'
//     }
//   },
//   {
//     path: 'register',
//     component: RegisterComponent,
//     data: {
//       title: 'Register Page'
//     }
//   },
//   {
//     path: '',
//     component: DefaultLayoutComponent,
//     data: {
//       title: 'Home'
//     },
//     children: [
//       {
//         path: 'base',
//         loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
//       },
//       {
//         path: 'buttons',
//         loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
//       },
//       {
//         path: 'charts',
//         loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
//       },
//       {
//         path: 'dashboard',
//         loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
//       },
//       {
//         path: 'icons',
//         loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
//       },
//       {
//         path: 'notifications',
//         loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
//       },
//       {
//         path: 'theme',
//         loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
//       },
//       {
//         path: 'widgets',
//         loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
//       }
//     ]
//   },
//   { path: '**', component: P404Component }
// ];

// @NgModule({
//   imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
//   exports: [ RouterModule ]
// })
// export class AppRoutingModule {}
