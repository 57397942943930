import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule, CurrencyPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helper';
import { appInitializer } from './helper/app.initializer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { NgbActiveModal, NgbModalModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DragableDirective } from './shared/directives/dragDrop.directive';
import { LoginSsoComponent } from './login/login-sso.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileManagerComponent } from './modules/file-manager/file-manager/filemanager';
import { CarStructureBaseComponent } from './core/bases/car-structure-base/car-structure-base.component';
import { AuthenticationService, FileManagerService, NavigationService, UserService } from './core/services';
import { BaseComponent } from './core/bases/base/base.component';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LoginExternalComponent } from './login/login-external/login-external.component';
import { AgmCoreModule } from '@agm/core';
import { ContextMenuModule } from 'ngx-contextmenu';

//storage factory cho angular-oauth2-oidc => lưu thông tin vào localStorage thay vì sessionStorage
//Nếu dùng sessionStorage thì mỗi tab sẽ có 1 session riêng => cần authen lại
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgbModalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBcvt68iGFu-9Zkw43sXzn8vVmkYHpLVek',
      libraries: ['places']
    }),
    // ----------------------

    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    ContextMenuModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    // ----------------------
    LoginComponent,
    UserComponent,
    SelectLanguageComponent,
    LoginSsoComponent,
    CarStructureBaseComponent,
    BaseComponent,
    LoginExternalComponent,
    // DirectoryComponent,
    // FileManagerComponent,
    // DragableDirective,
  ],
  providers: [
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    IconSetService,
    NgbActiveModal,
    // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: OAuthStorage, useFactory: storageFactory },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthenticationService,
    NavigationService,
    FileManagerService,
    UserService,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
