import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService, FileManagerService } from '../../../../core/services';
import { DirectoryModel, DirectoryUpdateModel, PrivacyEnum } from '../../../../shared/models/fileinfo.model';
import { finalize } from 'rxjs/operators';
import { OptionMenusComponent } from '../option-menus/option-menus.component';
import { ContextMenuService } from 'ngx-contextmenu';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpErrorResponse } from '../../../../shared/models/responsedata';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'storage-tree-list-item',
  templateUrl: './tree-list-item.component.html',
  styleUrls: ['./tree-list-item.component.scss']
})
export class TreeListItemComponent implements OnInit, OnChanges {
  @Input('item') item: DirectoryModel;
  @Input('selectedDirectory') selectedDirectory: DirectoryModel;
  @Output('select') select: EventEmitter<DirectoryModel> = new EventEmitter<DirectoryModel>();
  @Output('delete') delete: EventEmitter<DirectoryModel> = new EventEmitter<DirectoryModel>();
  // @Output('delete') delete: EventEmitter<{ deletedItem: DirectoryModel, parentItem: DirectoryModel }> = new EventEmitter<{ deletedItem: DirectoryModel, parentItem: DirectoryModel}>();
  
  @ViewChild(OptionMenusComponent) rightClick: OptionMenusComponent;
  directoryToUpdate: DirectoryUpdateModel;
  _item: DirectoryModel;
  _selectedDirectory: DirectoryModel;
  modalRef: BsModalRef;

  
  constructor(
    private filemanagerService: FileManagerService, 
    private spinner: NgxSpinnerService,
    private contextMenuService: ContextMenuService,
    private modalService: BsModalService,
    private alertifyService: AlertifyService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!!changes.item){
      this._item = changes.item.currentValue;
      if(this._item.subDirectories == null){
        this._item.subDirectories = [];
      }
    }
    if(!!changes.selectedDirectory){
      this._selectedDirectory = changes.selectedDirectory.currentValue;
    }
  }

  ngOnInit(): void {
  }

  getSubDirectories(event: any) {
    this.spinner.show('folders-spinner');
    this.filemanagerService.getDirectories(this._item.id).pipe(
        finalize(()=> {
            this.spinner.hide('folders-spinner');
        })
    ).subscribe(res => {
      if(!!res){
        let subDirectories = res as DirectoryModel[];
        this._item.subDirectories =  subDirectories;
        if(!!event){
          event.target.setAttribute('subDirectoriesLoaded', 'true');
        }
        
      }
    });
  }

  toggleSubFolder(directory: DirectoryModel, event: any){
    if(!!directory){
        directory.isExpanded = !directory.isExpanded;

        var subDirectoriesLoaded = event.target.getAttribute('subDirectoriesLoaded');
        if (subDirectoriesLoaded == 'true') {
            return;
        }
        this.getSubDirectories(event);
    }
  }

  handleCreateDirectoryEvent(template: TemplateRef<any>){
    this.directoryToUpdate = {
      id: 0,
      name: '',
      parentId: this._item.id,
      privacy: PrivacyEnum.Public
    };

    this.modalRef = this.modalService.show(template, { backdrop: 'static', class: 'modal-sm modal-dialog-centered' });
  }

  handleUpdateDirectoryEvent(template: TemplateRef<any>){
    this.directoryToUpdate = {
      id: this._item.id,
      name: this._item.name,
      parentId: this._item.parentId,
      privacy: this._item.privacy
    };

    this.modalRef = this.modalService.show(template, { backdrop: 'static', class: 'modal-sm modal-dialog-centered' });
  }

  handleDeleteDirectoryEvent(){
    this.alertifyService.confirm(this.translate.instant('FileManagementModule.ConfirmDeleteFolder'), null, ()=> {
      this.spinner.show();
      this.filemanagerService.deleteDirectory(this._item.id)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        ).subscribe(res => {
          this.toastr.success(this.translate.instant('Common.DeleteSuccess'));
          this.delete.emit({...this._item});
          this._item = null;
        },
        err => {
          let customErr = new CustomHttpErrorResponse(err);
          let msg = null;
          if(customErr.hasAPICustomError){
            msg = customErr.apiCustomError[0];
          }
          else{
            msg = `<div class="text-muted text-uppercase font-weight-bold font-xs">${this.translate.instant('Common.UnexpectedError')}</div>`;
          }

          
          if(customErr.hasTradeId)
          {
              msg += `<div class="text-muted font-weight-bold font-xs">Trade id: ${customErr.tradeId}</div>`
          }
          this.alertifyService.errorPopup(msg, null);
        });
    });
  }

  onDeleteSubFolder(event: DirectoryModel){
    this.delete.emit(event);
    this._item.subDirectories = this._item.subDirectories.filter(x=> x.id != event.id);
    if(this._item.subDirectories == null || this._item.subDirectories.length == 0){
      this._item.hasSubDirectories = false;
    }
  }


  onDirectorySaved(directory: DirectoryUpdateModel){
    if(!!directory){
      if(this._item.id == directory.id){//TH edit folder hiện tại
        this._item = { ...this._item, ...directory};
      }
      else{ //TH thêm sub directory
        this._item.hasSubDirectories = true;
        this._item.isExpanded = true;//T-TEMP, not sure should this one expands automaticaly
        this._item.subDirectories = [...this._item.subDirectories];
        this._item.subDirectories.push({
          id: directory.id,
          name: directory.name,
          hasSubDirectories: false,
          isExpanded: false,
          subDirectories: [], 
          parentId: directory.parentId,
          privacy: directory.privacy
        });
      }
    }
    this.modalRef.hide();
  }

  onCloseUpdateDirectoryModal(){
    this.modalRef.hide();
  }
  
  public onContextMenu($event: MouseEvent, item: any): void {
    this.contextMenuService.show.next({
      contextMenu: this.rightClick.contextMenu,
      event: $event,
      item: item

    });
    $event.preventDefault();
    $event.stopPropagation();
  }
}
