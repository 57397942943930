import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { CreateNewsModel, NewsDetailModel, NewsListModel, RequestCreate, RequestUpdate, ResponseNewsModel, UpdateNewsModel } from "../../shared/models/news.model";

@Injectable({ providedIn: 'root' })
export class NewsService {
    private baseApi: string;
    private newsApi: string;
    private restApi: string;

    private apiGetList: string;
    private apiGetDetail: string;
    private apiCreate: string;
    private apiUpdate: string;

    constructor(private http: HttpClient) {
        // this.baseApi = environment.base.href;
        // this.newsApi = environment.articleApi.href;
        // this.restApi = `${this.baseApi}articles`;

        this.apiGetList = `${this.newsApi}News/Filter?pageIndex=:pageIndex&pageSize=:pageSize`;
        this.apiGetDetail = `${this.newsApi}News/GetById?id=:id`;
        this.apiCreate = `${this.newsApi}News/Create`;
        this.apiUpdate = `${this.newsApi}News/Update`;
    }

    getList(pageIndex: number, pageSize: number, keyword: string, categoryId: number, status: number): Observable<NewsListModel> {
        let apiUrl = this.apiGetList.replace(":pageIndex", String(pageIndex)).replace(":pageSize", String(pageSize));
        if (keyword != '') apiUrl = `${apiUrl}&keyword=${keyword}`;
        if (categoryId >= 0) apiUrl = `${apiUrl}&categoryId=${categoryId}`;
        if (status > 0) apiUrl = `${apiUrl}&status=${status}`;
        else apiUrl = `${apiUrl}&status=-1`;
        return this.http.get<NewsListModel>(apiUrl);
    }

    getDetail(id: number): Observable<NewsDetailModel> {
        return this.http.get<NewsDetailModel>(this.apiGetDetail.replace(":id", String(id)));
    }

    create(request: RequestCreate): Observable<ResponseNewsModel> {
        return this.http.post<ResponseNewsModel>(this.apiCreate, request);
    }

    update(request: RequestUpdate): Observable<ResponseNewsModel> {
        return this.http.put<ResponseNewsModel>(this.apiUpdate, request);
    }

    countByCategory(categoryid: number) {
        return this.http.get<number>(`${this.restApi}/category/count/${categoryid}`);
    }
}