<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container" *ngIf="!isssologin">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" formControlName="username" class="form-control" placeholder="Username"
                      autocomplete="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" formControlName="password" class="form-control" placeholder="Password"
                      autocomplete="current-password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                      required>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                  </div>
                  <div class="input-group mb-4">
                    <app-select-language></app-select-language>
                  </div>
                  <div class="row">
                    <div *ngIf="error" role="alert" class="alert alert-danger">{{error}}</div>
                    <div class="col-6">
                      <button type="submit" [disabled]="loading" class="btn btn-primary px-4">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0">Forgot password?</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <h2>DVG AutoPortal CMS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>