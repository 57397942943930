import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SalonBoxSettingModel, SalonInBoxSettingUpdateModel } from '../../shared/models/salon/salon-box-setting.model';
import { SalonBoxInListModel } from '../../shared/models/salon/salon-box.model';
import { ActiveStatus } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class SalonBoxService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('SALON_URL') private salonUrl: string
  ) {
    this.restApi = `${this.salonUrl}salon-box`;
  }

  getAll(): Observable<SalonBoxInListModel[]>{
    return this.http.get<SalonBoxInListModel[]>(`${this.restApi}`);
  }

  setDetail(model: SalonInBoxSettingUpdateModel){
    return this.http.post(`${this.restApi}/${model.salonBoxId}/set-details`, model.items); 
  }

  getListSalonBoxDetail(salonBoxId: number){
    return this.http.get<SalonBoxSettingModel>(`${this.restApi}/box-setting-detail/${salonBoxId}`);
  }
}
