import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, forkJoin, pipe } from "rxjs";
import { FileHandle } from "../../../shared/directives/dragDrop.directive";
import { DirectoryModel, DirectoryUpdateModel, EventHandleModel, FileDetailModel, FileModel, FileTypeEnum, PostFileModel, PrivacyEnum, UploadConfig, UploadFileResponse } from "../../../shared/models/fileinfo.model";
import * as CONSTANT from '../../../shared/constants/consts';
import { FileManagerService } from "../../../core/services";
import { Constants } from "../../../helper";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize, map } from "rxjs/operators";
import { Guid } from "guid-typescript";
import { OptionMenusComponent } from "../components/option-menus/option-menus.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ContextMenuService } from "ngx-contextmenu";

@Component({
    selector: 'file-manager',
    styleUrls: ['./filemanager.css'],
    templateUrl: './filemanager.html'
})
export class FileManagerComponent implements OnInit {

    public isCollapsed: boolean;
    directories: DirectoryModel[] = null;
    files: FileModel[];
    selectedFiles: FileModel[];
    selectedSingleFile: FileModel;
    selectedDirectory: DirectoryModel;
    fileTypeEnum = FileTypeEnum;
    uploadConfig: UploadConfig;
    // ========================
    directoryToAdd: DirectoryUpdateModel;
    folderForm: FormGroup;
    optionMenus = OptionMenusComponent;
    modalRef: BsModalRef;
    // ========================

    // @ViewChild('dynamic_component', { read: ViewContainerRef }) dynamicComponentContainer !: ViewContainerRef;

    @Input() singlechoice: boolean = true;
    @Output() selectFile: EventEmitter<any> = new EventEmitter();

    constructor(
        private filemanagerService: FileManagerService,
        public spinner: NgxSpinnerService,
        private fb: FormBuilder,
        private modalService: BsModalService,
    ) 
    {
        this.isCollapsed = true;
        this.selectedFiles = [];
        this.getUploadSettings();

        this.selectedSingleFile = new FileModel();
    }

    showAddDirectoryModal(template: TemplateRef<any>){
        this.directoryToAdd = {
            id: 0,
            name: '',
            parentId: 0,
            privacy: PrivacyEnum.Public
        };

        this.modalRef = this.modalService.show(template, { backdrop: 'static', class: 'modal-sm modal-dialog-centered' });
    }

    onDirectorySaved(directory: DirectoryUpdateModel){
        if(!!directory){
            this.directories.push({
                id: directory.id,
                name: directory.name,
                hasSubDirectories: false,
                isExpanded: false,
                subDirectories: [],
                parentId: directory.parentId,
                privacy: directory.privacy
            });
            if(this.selectedDirectory == null){
                this.selectedDirectory = {...directory, hasSubDirectories: false, isExpanded: false, subDirectories: []};
            }
        }
        this.modalRef.hide();
    }

    onCloseUpdateDirectoryModal(){
        this.modalRef.hide();
    }

    getUploadSettings(directory: string = '') {
        //T-TEMP, đang gọi mỗi khi đc khởi tạo
        this.filemanagerService.getUploadSettings(directory, (res) => {
            this.uploadConfig = res;
            localStorage.setItem(Constants.storageFileDownloadHost, this.uploadConfig?.downloadHost);
        });
    }

    ngOnInit(): void {
        this.getDirectories();
    }

    getDirectories(directoryId: number = 0, callback: any = null) {
        this.spinner.show('folders-spinner');
        this.filemanagerService.getDirectories(directoryId).pipe(
            finalize(()=> {
                this.spinner.hide('folders-spinner');
            })
        ).subscribe(res => {
            if (directoryId <= 0) {
                this.directories = res;
                this.selectedDirectory = res[0];
                this.getFiles(this.selectedDirectory?.id);
            }
            else {
                if (callback)
                    callback(res);
            }
            return res;
        });
    }

    getFiles(directoryId: number = 0) {
        this.spinner.show('files-spinner');
        this.filemanagerService.getFiles(directoryId)
        .pipe(
            finalize(()=> {
                this.spinner.hide('files-spinner');
            })
        ).subscribe(res => {
            this.files = res;
            this.files.forEach(item => {
                item.classList = ['col-sm-6', 'col-md-4'];
                if (this.selectedFiles.find(x => x.id == item.id) != null) {
                    item.classList.push('selected');
                }
            });
            return res;
        });
    }

    public handleDirectorySelection(directory: DirectoryModel ) : void {
        if(!!directory){
            this.selectedDirectory = directory;
            this.getFiles(directory.id);
            this.getUploadSettings(this.selectedDirectory.name);//T-TEMP, sao a DŨng lại call cái này ở đây
        }
	}

    handleDirectoryDeleted(directory: DirectoryModel){
        if(!!directory)
        {
            if(directory.id == this.selectedDirectory?.id)//Nếu xóa đúng item đang đc chọn, thì auto chọn luôn item đầu tiên của treelist (to be update: auto chọn item đầu tiên của parent)
            {
                if(directory.parentId == 0){
                    this.directories = this.directories.filter(x=> x.id != directory.id);
                }
                if(this.directories.length > 0){
                    this.selectedDirectory = this.directories[0];
                    this.getFiles(this.selectedDirectory?.id);
                    
                }
                else{
                    this.selectedDirectory = null
                    this.files = [];
                }
            }

            if(this.directories == null || this.directories.length == 0)
            {
                this.files = [];
            }
        }
    }

    getFileTypeName(type: FileTypeEnum): string {
        switch (type) {
            default:
            case FileTypeEnum.Image: return 'Image';
            case FileTypeEnum.Doc: return 'Doc(x)';
            case FileTypeEnum.Video: return 'Video';
            case FileTypeEnum.Pdf: return 'PDF';
            case FileTypeEnum.Text: return 'Text';
            case FileTypeEnum.Unknow: return 'Unknow';
        }
    }

    getSizeUnit(size: number): string {
        if (size > 0) {
            return Math.round(size / 1024) + ' KB';
        }
        return '';
    }

    getFilePath(fileInfo: FileModel, crop: string = '/crop/120x120'): string {
        var element = '';
        switch (fileInfo.type) {
            case 1:
                element = `"${this.uploadConfig?.downloadHost}${crop}/${fileInfo.path}"`;
                break;
            case 2:
                break;
        }
        return element;
    }

    selectItem(fileInfo: FileModel) {
        if (this.singlechoice) {
            //document.querySelectorAll('.selected').forEach(x => x.classList.remove('selected'));
            this.files.forEach(x => x.classList.indexOf('selected') != -1 ? x.classList.splice(x.classList.indexOf('selected')) : x);
            this.selectedFiles = [];
            fileInfo.classList.push('selected');
            this.selectedFiles.push(fileInfo);
            this.selectedSingleFile = fileInfo;
            //document.getElementById(`__file__${fileInfo.id}`).classList.add('selected');
        } else {

            let index: number = -1, existedFile: FileModel;

            this.selectedFiles.forEach((element, idx) => {
                if (element.id == fileInfo.id) {
                    existedFile = element;
                    index = idx;
                    // fileInfo.classList.splice(fileInfo.classList.indexOf('selected'));
                    // this.selectedFiles.splice(index, 1);
                }
            });

            if (index > -1) {
                //document.getElementById(`__file__${fileInfo.id}`).classList.remove('selected');
                fileInfo.classList.splice(fileInfo.classList.indexOf('selected'));
                this.selectedFiles.splice(index, 1);
            } else {
                this.selectedFiles.push(fileInfo);
                //document.getElementById(`__file__${fileInfo.id}`).classList.add('selected');
                fileInfo.classList.push('selected');
            }
            if (this.selectedFiles != null) {
                if (this.selectedFiles.length == 1) {
                    this.selectedSingleFile = this.selectedFiles[0];
                }
                else this.selectedSingleFile = new FileModel();
            }
            else this.selectedSingleFile = fileInfo;
        }
        if (this.selectFile) this.selectFile.emit(this.selectedFiles);
    }

    getTypeByExt(ext: string): FileTypeEnum {
        switch (ext.toLocaleLowerCase()) {
            case '.jpg':
            case '.jpeg':
            case '.gif':
            case '.webp':
            case '.png':
            case '.bmp':
                return FileTypeEnum.Image;
            case '.mp4':
            case '.webm':
            case '.mov':
                return FileTypeEnum.Video;
            case '.pdf':
                return FileTypeEnum.Pdf;
            case '.doc':
            case '.docx':
                return FileTypeEnum.Doc;
            case '.txt':
            case '.text':
                return FileTypeEnum.Text;
            case '':
                return FileTypeEnum.Unknow;
        }
    }

    async filesDropped(files: FileHandle[]) {
        var self = this;
        if (files != null && files.length > 0) {
            var arrPromise: any[] = [];
            files.forEach(x => {
                var upload = this.filemanagerService.uploadFile(x.file, this.uploadConfig);
                arrPromise.push({ fileInfo: x, upload });
            })
            Promise.all(arrPromise).then(function (values) {
                var postData: PostFileModel[] = [];
                values.forEach(y => {
                    y.upload.subscribe(res => {
                        var fileInfo = y.fileInfo.file;
                        var path = res.OK;
                        var extension = path.substring(path.lastIndexOf('.'));
                        var fileName = path.substr(path.lastIndexOf('/') + 1).replace(extension, '');

                        postData.push(new PostFileModel(
                            fileName,
                            path,
                            self.selectedDirectory.id,
                            self.getTypeByExt(extension),
                            extension,
                            fileInfo.size,
                            0,
                            'sondt', //self.authenticationService.getCurrentUser().username //T-TEMP
                        ));

                        if (postData.length == values.length) {
                            self.filemanagerService.saveFile(postData).pipe().subscribe(res => {
                                self.getFiles(self.selectedDirectory.id);
                                return res;
                            });
                        }

                        return res;
                    });
                });
            });
        }
    }

    upload(files: FileList) {
        this.spinner.show('files-spinner');
        if (files == null || files.length <= 0) return;
        var self = this;
        var arrPromise: any[] = [];

        let uploadTasks = [];//Array store all observables for upload file to storage server
        let filesMap = [];//Array to store files with guid, to map the file in folkJoin result using guid
        for (var i = 0; i < files.length; i++) {
            let guid = Guid.create();//guid: Guid.create() to identify which file belong to a folkJoin result
            var file = files.item(i);
            filesMap.push({guid: guid, file});
            var uploadTask$ = this.filemanagerService.uploadFile(file, this.uploadConfig);
            uploadTasks.push(uploadTask$.pipe(map(_=>({guid: guid, uploadResult: _}))));
            
            var upload = this.filemanagerService.uploadFile(file, this.uploadConfig);
            arrPromise.push({ fileInfo: file, upload });
        }
        forkJoin(uploadTasks)
        .pipe(
            finalize(()=>{
                this.spinner.hide('files-spinner');
            })
        ).subscribe(results => {
            var postData: PostFileModel[] = [];
            results.forEach((res: {guid: Guid, uploadResult: UploadFileResponse}) => {
                var uploadResult = res.uploadResult;
                if (uploadResult != null && uploadResult.OK != null && uploadResult.OK !== '')
                {
                    let fileGuid = res.guid;
                    let fileInfo = filesMap.find(f => f.guid === fileGuid)?.file;
                    if(!!fileInfo) {
                        let path = uploadResult.OK;
                        let extension = path.substring(path.lastIndexOf('.'));
                        let fileName = path.substr(path.lastIndexOf('/') + 1).replace(extension, '');

                        postData.push(new PostFileModel(
                            fileName,
                            path,
                            self.selectedDirectory.id,
                            self.getTypeByExt(extension),
                            extension,
                            fileInfo.size,
                            0,
                            'sondt', //self.authenticationService.getCurrentUser().username T-TEMP
                        ));
                    }
                    
                }
            });

            //post data to common API to store fileInfo into database
            if (postData.length > 0) {
                this.spinner.show('files-spinner');
                this.filemanagerService.saveFile(postData)
                    .pipe(
                        finalize(()=>{
                            this.spinner.hide('files-spinner');
                        })
                    )   
                    .subscribe(res => {
                        this.getFiles(this.selectedDirectory.id);
                        return res;
                    });
            }
        });

        return;
    }
}
