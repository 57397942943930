import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveStatus } from '../enums/active-status.enum';
import { StringHelper } from '../../helper';
import { BodyTypeInListFilter } from '../../shared/models/body-type.model';

@Injectable({
  providedIn: 'root'
})
export class BodyTypeService {
  restApi: string;
  
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') carInfoUrl: string
  ) {
    this.restApi = `${carInfoUrl}body-type`;
  }

  getAll(status: ActiveStatus = ActiveStatus.All): Observable<BodyTypeInListFilter[]> {
    let params = new HttpParams();
    if(status != null){
      params = params.append('status', `${status}`);
    }
    return this.http.get<BodyTypeInListFilter[]>(`${this.restApi}`, {params:  params});
  }
}
