import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let newCarAPI = '';
let promotionAPI = '';
let commonAPI = '';
let carInfoAPI = '';
let salonAPI = '';

// Tạm thời hardcode downloadHost upload ảnh của từng dự án trong environment
if(localStorage.getItem('downloadHost') == null){
  localStorage.setItem('downloadHost', environment.downloadHost);
}

if(environment.development){
  newCarAPI = `${environment.newCarApi.href}/api/${environment.newCarApi.version}/`;
  commonAPI = `${environment.commonApi.href}/api/${environment.commonApi.version}/`;
  carInfoAPI = `${environment.carInfoApi.href}/api/${environment.carInfoApi.version}/`;
  promotionAPI = `${environment.promotionApi.href}/api/${environment.promotionApi.version}/`;
  salonAPI = `${environment.salonApi.href}/api/${environment.salonApi.version}/`;
}
else{
  newCarAPI = `${environment.base.apiGateWay}/${environment.newCarApi.gateWaySurfix}/api/${environment.newCarApi.version}/`;
  commonAPI = `${environment.base.apiGateWay}/${environment.commonApi.gateWaySurfix}/api/${environment.commonApi.version}/`;
  carInfoAPI = `${environment.base.apiGateWay}/${environment.carInfoApi.gateWaySurfix}/api/${environment.carInfoApi.version}/`;
  promotionAPI = `${environment.base.apiGateWay}/${environment.promotionApi.gateWaySurfix}/api/${environment.promotionApi .version}/`;
  salonAPI = `${environment.base.apiGateWay}/${environment.salonApi.gateWaySurfix}/api/${environment.salonApi .version}/`;
}

const providers = [
  { provide: 'GATEWAY_URL', useFactory: () => `${environment.base.apiGateWay}/${environment.base.version}/`, deps: [] },
  { provide: 'SSO_LOGIN_ENPOINT', useFactory: () => environment.ssoLoginEndpoint, deps: [] },
  { provide: 'NEWCARS_URL', useFactory: () => newCarAPI, deps: [] },
  { provide: 'COMMON_URL', useFactory: () => commonAPI, deps: [] },
  { provide: 'CARINFO_URL', useFactory: () => carInfoAPI, deps: [] },
  { provide: 'PROMOTION_URL', useFactory: () => promotionAPI, deps: [] },
  { provide: 'SALON_URL', useFactory: () => salonAPI, deps: [] },
];

platformBrowserDynamic(providers).bootstrapModule(AppModule, {
  useJit: true,
  preserveWhitespaces: true
})
  .catch(err => console.log(err));
