import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    public translate: TranslateService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };

    translate.addLangs(['en', 'vi-VN']);
    translate.setDefaultLang('en');

    let lan = localStorage.getItem("lan");
    if(lan !== null && lan !== '' && lan !== undefined)
    {
      translate.use(lan);
    }else{
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|vi-VN/) ? browserLang : 'en');
    }
  }

  ngOnInit() {
    const version = 1;
    // this.addJsToElement("https://cdn.ckeditor.com/4.16.1/full-all/ckeditor.js");
    // this.addJsToElement("https://cdn.ckeditor.com/ckeditor5/33.0.0/classic/ckeditor.js");
    this.addJsToElement("assets/ckeditor-init.js?v=" + version);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    if (location.href.includes("salon/update")) {
      this.addJsToElement("https://maps.googleapis.com/maps/api/js?key=AIzaSyBcvt68iGFu-9Zkw43sXzn8vVmkYHpLVek", true, true);
    }
  }

  addJsToElement(src: string, async = false, defer = false): HTMLScriptElement {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = src;
    node.async = async;
    node.defer = defer;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    return node;
  }
}
