import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StringHelper } from "../../helper";
import { BaseListFilter } from "../../shared/models/base-list-filter";
import { CustomerListModel, CustomerModel } from "../../shared/models/customer.model";
import { ResponseData } from "../../shared/models/responsedata";
import { UserSearchRequestModel, UserDetailModel, UserRequestUpdateModel, UserModel } from "../../shared/models/user.model";

@Injectable({ providedIn: 'root' })
export class UserService {

    private userListAllUrl = '';
    private userListUrl = '';
    private userDetailUrl = '';
    private userUpdateUrl = '';

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.userListAllUrl = `${commonUrl}users`;
        this.userListUrl = `${commonUrl}users/getlist`;
        this.userDetailUrl = `${commonUrl}users/detail/:id`;
        this.userUpdateUrl = `${commonUrl}users/update`;
    }

    getAll(): Observable<UserModel[]> {
        return this.http.get<UserModel[]>(this.userListAllUrl);
    }

    getUserList(request: UserSearchRequestModel): Observable<ResponseData<UserDetailModel[]>> {
        if (request.pageSize == undefined) request.pageSize = 10;
        if (request.keyword == undefined) request.keyword = "";
        if (request.pageIndex == undefined || request.pageIndex < 1) request.pageIndex = 1;
        return this.http.post<ResponseData<UserDetailModel[]>>(this.userListUrl, request);
    }

    fillter(request: UserSearchRequestModel): Observable<BaseListFilter<UserModel>> {
      if (!request.pageIndex || request.pageIndex <= 0) request.pageIndex = 1;
        if (!request.pageSize || request.pageSize <= 0) request.pageSize = 10;

        let parameters = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
                let value = request[key];
                parameters = parameters.set(key, value);
            }
        }
      return this.http.get<BaseListFilter<UserModel>>(this.userListUrl, { params : parameters});
    }

    getUserDetail(id: number): Observable<UserDetailModel> {
        const apiUrl = this.userDetailUrl.replace(":id", String(id));
        return this.http.get<UserDetailModel>(apiUrl);
    }

    updateUser(request: UserRequestUpdateModel): Observable<ResponseData<UserRequestUpdateModel>> {
        return this.http.post<ResponseData<UserRequestUpdateModel>>(this.userUpdateUrl, request);
    }
}
