import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ArticleSourceListFilter, ArticleSourceUpdateModel } from '../../shared/models/article-source.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleSourceService{
    restApi: string;

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.restApi = `${commonUrl}sources`;
    }

    getList(filter: ArticleSourceListFilter): Observable<ArticleSourceListFilter> {
        let queryParams: string = null;
        if (filter.keyword != '') queryParams = !!queryParams ? `${queryParams}&keyword=${filter.keyword}`: `?keyword=${filter.keyword}`;
        queryParams = !!queryParams ? `${queryParams}&PageNumber=${filter.pageNumber}`: `?PageNumber=${filter.pageNumber}`;
        queryParams = !!queryParams ? `${queryParams}&PageSize=${filter.pageSize}`: `?PageSize=${filter.pageSize}`;
        return this.http.get<ArticleSourceListFilter>(`${this.restApi}${queryParams}`);
    }

    create(model: ArticleSourceUpdateModel) {
        return this.http.post(this.restApi, model);
    }

    update(model: ArticleSourceUpdateModel) {
        return this.http.put(this.restApi, model);
    }

}