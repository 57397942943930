<context-menu #basicMenu [menuClass]="'option-menus'">
	<ng-template contextMenuItem (execute)="createDirectory.emit(null);">
		{{ 'FileManagementModule.NewFolder' | translate }}
	</ng-template>
    <ng-template contextMenuItem (execute)="updateDirectory.emit(null);">
		{{ 'FileManagementModule.UpdateFolder' | translate }}
	</ng-template>
	<ng-template contextMenuItem let-item (execute)="deleteDirectory.emit(null);">
		{{ 'Common.Delete' | translate }}
	</ng-template>
</context-menu>