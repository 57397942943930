import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StringHelper } from "../../helper";
import { BaseListFilter } from "../../shared/models/base-list-filter";
import { AccountInListModel, FilterAccountModel } from "../../shared/models/account.model";

@Injectable({ providedIn: 'root' })
export class AccountService {

    private restApi: string;
    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.restApi = `${commonUrl}fe-accounts`;
    }

    filter(request: FilterAccountModel): Observable<BaseListFilter<AccountInListModel>> {
        if (!request.pageNumber || request.pageNumber <= 0) request.pageNumber = 1;
        if (!request.pageSize || request.pageSize <= 0) request.pageSize = 10;

        let parameters = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
                let value = request[key];
                parameters = parameters.set(key, value);
            }
        }
        return this.http.get<BaseListFilter<AccountInListModel>>(this.restApi, { params: parameters });
    }

    getList(request: FilterAccountModel): Observable<BaseListFilter<AccountInListModel>> {
        if (!request.pageNumber || request.pageNumber <= 0) request.pageNumber = 1;
        if (!request.pageSize || request.pageSize <= 0) request.pageSize = 10;

        let params = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
              params = params.append(key, request[key]);
            }
            else{
              if (Array.isArray(request[key])) {
                request[key].forEach(element => {
                  params = params.append(key, element);
                });
              }
            }
          }
        return this.http.get<BaseListFilter<AccountInListModel>>(this.restApi, { params: params });
      }
}
