import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidationOptions } from '../shared/models/form.model';

export class FormHelper{
  public static getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
    console.log(key);
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
  }
  public static setValidators(control: AbstractControl, validator: ValidatorFn | ValidatorFn[]){
    control.setValidators(validator);
    control.updateValueAndValidity();
  }
  public static clearValidators(control: AbstractControl){
    control.clearValidators()
    control.updateValueAndValidity();
  }

  //#region Validation helper
  public static required(name: string, control: FormGroup | AbstractControl | null, option: FormValidationOptions){
    if(control == null){
      return false;
    }
    return control.get(name).hasError('required') && (control.get(name).touched || option?.isFormSubmitted);
  }

  public static dropListRequired(name: string, control: FormGroup | AbstractControl | null, option: FormValidationOptions){
    if(control == null){
      return false;
    }
    return control.get(name).hasError('dropListRequired') && (control.get(name).touched || option?.isFormSubmitted);
  }

  public static arrayRequired(arrayDataControl: AbstractControl, option: FormValidationOptions){
    if(!!arrayDataControl == false){
      return false;
    }
    return arrayDataControl.hasError('arrayRequired') && (arrayDataControl.touched || option.isFormSubmitted);
  }

  public static formArrayRequired(formArray: FormArray | AbstractControl, option: {isFormSubmitted: boolean}){
    if(!!formArray == false){
      return false;
    }
    return formArray.hasError('formArrayRequired') && option.isFormSubmitted;
  }

  public static invalidFormat(name: string, control: FormGroup | AbstractControl | null, option: FormValidationOptions){
    if(control == null){
      return false;
    }
    return (control.get(name).hasError('format') || control.get(name).hasError('pattern')) && (control.get(name).dirty || option?.isFormSubmitted) && !FormHelper.required(name, control, option);
  }

  public static invalidPartern(name: string, control: FormGroup | AbstractControl | null, option: FormValidationOptions){
    if(control == null){
      return false;
    }
    return control.get(name).hasError('pattern') && (control.get(name).touched || option?.isFormSubmitted);
  }
  //#endregion
}