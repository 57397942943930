import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationResponse } from '../shared/models/identity.model';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Constants } from './constants/constants';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/services/authentication.service';


@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    baseUrl: string;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.baseUrl = commonUrl;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        //var currentUser;
        // const currentUser: AuthenticationResponse = this.authenticationService.currentAuthenticate;
        // const isLoggedIn = currentUser && currentUser.jwtToken;
        // const isApiUrl = request.url.startsWith(this.baseUrl);
        // if (isLoggedIn && isApiUrl) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${currentUser.jwtToken}`
        //         }
        //     });
        // }
        request = this.addToken(request);
        return next.handle(request).pipe(
            catchError((err, caught) => {
                if(err instanceof HttpErrorResponse)
                {
                    if(err.status === 401){
                        return this.handle401Error(request, next);
                    }
                    else if(err.status >= 500) {
                        this.handleServerError();
                        return throwError(err);
                    }
                }
                return throwError(err);
            })
        );
    }



    private addToken(request: HttpRequest<any>) {
        const currentUser: AuthenticationResponse = this.authenticationService.currentAuthenticate;
        const isLoggedIn = currentUser && currentUser.jwtToken;
        const isApiUrl = request.url.startsWith(this.baseUrl);
    
        //Cái này truyền lên language để server translate 
        // let lan = localStorage.getItem("lan");
        // if(lan === null || lan === '' || lan === undefined)
        // {
        //   lan = "en";
        // }

        if (isLoggedIn && isApiUrl) {
            return request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.jwtToken}`,
                    // 'Accept-Language': lan
                }
            });
        }
        else {
            return request;
        }
    } 

    private handleServerError(){
        console.log('handleServerError');
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler){
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authenticationService.refreshToken().pipe(
                switchMap((res: any) => {
                  this.isRefreshing = false;
                  if(res != null || res != undefined || res != '')
                  {
                    this.refreshTokenSubject.next(res);
                  }
                  else{
                    this.refreshTokenSubject.next(null);
                  }
                  return next.handle(this.addToken(request));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.authenticationService.handleRefreshTokenError(this.router.routerState.snapshot.url);
                    return throwError(err);
                }));
        }
        else{
            //Nếu đang call API để refresh token thì subscrible change của refreshTokenSubject thôi, chứ ko call API nữa (TH nhiều request trả về 401)
            return this.refreshTokenSubject.pipe(
                filter(res => res != null),
                take(1),
                switchMap(data => {
                  return next.handle(this.addToken(request));
                }));
        }
    }
}
