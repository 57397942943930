import { HttpErrorResponse } from "@angular/common/http";
import { StringHelper } from "../../helper";

export class ResponseData<T> {
	success: boolean;
	message: string;
	errorcode: number;
	data: T;
	totalRows: number;
}

// export class ErrorResponse1{
// 	errors: string[] = [];
// 	trace_id: string;
// 	status: number;
// }

export class CustomHttpErrorResponse {
	constructor(primaryError: HttpErrorResponse) {
		this.error = primaryError.error;
		this.status = primaryError.status;
	}

	error: any | null;
	status?: number;

	get hasTradeId(){
		if (this.error == null)
		{
			return false;
		} 
		let tradeId = this.error.trace_id;
		return !StringHelper.isNullOrEmpty(tradeId) && tradeId != undefined;
	}
	get tradeId(){
		if(this.error == null)
		return null;
		return this.error.trace_id;
	}

	public get hasAPICustomError(){
		//Check xem http error có lỗi mà API catch được không
		if (this.error == null || this.status >= 500)
		{
			return false;
		}
		let apiErrors = this.error.errors as [];
		return apiErrors != null && apiErrors.length > 0;
	}

	get apiCustomError(): string[] {
		if (this.error == null || this.status >= 500)
		return [];
		return this.error.errors as [];
	}
}