<div class="container filemanager">
    <div class="row">
        <div class="col-md-3 folderlist" style="padding-left: 0; padding-right: 0">
            <ngx-spinner name="folders-spinner" [fullScreen]="false" type="ball-spin-clockwise" size="default" bdColor="rgba(255,255,255,0)" color="#DA1A21"></ngx-spinner>
            <div class="treeview-header">
                <ul class="nav navbar-nav options">
                    <li><a (click)="showAddDirectoryModal(tmlAddDirectory)" class="pointer"><span class="fa fa-plus"></span></a></li>
                    <li><a href="#"><span class="fa fa-question-circle "></span></a></li>
                    <!-- <li><a href="#"><span class="fa fa-lg fa-server"></span></a></li>
                    <li><a href="#"><span class="fa fa-lg fa-minus"></span></a></li>
                    <li><a href="#"><span class="fa fa-lg fa-window-maximize"></span></a></li>
                    <li><a href="#"><span class="fa fa-lg fa-times"></span></a></li> -->
                </ul>
            </div>
            <div class="treeview-container custom-scroll" style="height: 500px;">            
                <div class="storage-treeview">
                    <ul class="storage-treeview-list" tabindex="0" role="tree">
                        <storage-tree-list-item 
                            *ngFor="let dir of directories" 
                            [item]="dir"
                            [selectedDirectory]="selectedDirectory"
                            (select)="handleDirectorySelection($event)"
                            (delete)="handleDirectoryDeleted($event)">
                        </storage-tree-list-item>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6 filelist " appDragable (files)="filesDropped($event)" style="padding: unset;">
            <ngx-spinner name="files-spinner" [fullScreen]="false" type="ball-spin-clockwise" size="default" bdColor="rgba(255,255,255,0)" color="#DA1A21"></ngx-spinner>
            <div class="treeview-header filelist-header" style="padding-top: 6px;">
                <ul class="nav navbar-nav options" style="margin-bottom: 7px; padding-left: 15px">
                    <li *ngIf="!!selectedDirectory">
                        <label class="btn btn-primary btn-square btn-sm mb-0">
                            <input type="file" (change)="upload($event.target.files)" class="d-none" multiple/>
                            <span class="fa fa-upload"></span> Upload file
                        </label>
                    </li>
                </ul>
            </div>
            <div class="file-list-container custom-scroll" style="padding-top: 5px; padding-left: 15px; padding-right: 15px">
                <div class="animated fadeIn">
                    <div class="row">
                        <div class="ui-unselectable" [ngClass]="file.classList.join(' ')" [id]="'__file__' + file.id.toString()"
                            *ngFor="let file of files" (click)="selectItem(file)">
                            <div class="card">
                                <div class="card-header">
                                    <strong class="file-name">{{file.name}}</strong>
                                </div>
                                <div class="card-body">
                                    <div [ngSwitch]="file.type">
                                        <div *ngSwitchCase="fileTypeEnum.Image" class="file-item">
                                            <img class="item" [src]="uploadConfig?.downloadHost + '/crop/120x120/' + file.path" />
                                        </div>
                                        <div *ngSwitchCase="fileTypeEnum.Pdf" class="file-item">
                                            <i class="fa fa-file-pdf-o fa-lg pdf"></i>
                                        </div>
                                        <div *ngSwitchCase="fileTypeEnum.Video" class="file-item">
                                            <i class="fa fa-file-video-o fa-lg video"></i>
                                        </div>
                                        <div *ngSwitchCase="fileTypeEnum.Doc" class="file-item">
                                            <i class="fa fa-file-word-o fa-lg doc"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/.row-->
                </div>
            </div>
            
        </div>

        <div class="col-md-3" style="border: solid 1px #ccc;">
            <div class="card card-selected-file" *ngIf="selectedSingleFile && selectedSingleFile.id > 0">
                <div class="card-body single-selected-file">
                    <div class="file-source">
                        <div [ngSwitch]="selectedSingleFile.type">
                            <div *ngSwitchCase="fileTypeEnum.Image" class="file-item-selected">
                                <img class="item"
                                    [src]="uploadConfig?.downloadHost + '/crop/300x300/' + selectedSingleFile.path" />
                            </div>
                            <div *ngSwitchCase="fileTypeEnum.Pdf" class="file-item-selected">
                                <i class="fa fa-file-pdf-o fa-lg pdf"></i>
                            </div>
                            <div *ngSwitchCase="fileTypeEnum.Video" class="file-item-selected">
                                <i class="fa fa-file-video-o fa-lg video"></i>
                            </div>
                            <div *ngSwitchCase="fileTypeEnum.Doc" class="file-item-selected">
                                <i class="fa fa-file-word-o fa-lg doc"></i>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <ul>
                            <li>{{'Common.Name' | translate}}: <strong>{{selectedSingleFile.name}}</strong></li>
                            <li>{{'Common.DirectoryName' | translate}}:
                                <strong>{{selectedSingleFile.directoryName}}</strong>
                            </li>
                            <li>{{'Common.CreatedDate' | translate}}:
                                <strong>{{selectedSingleFile.createdDate}}</strong>
                            </li>
                            <li>{{'Common.LastEditedDate' | translate}}:
                                <strong>{{selectedSingleFile.modifiedDate}}</strong>
                            </li>
                            <li>{{'Common.Owner' | translate}}: <strong>{{selectedSingleFile.owner}}</strong></li>
                            <li>{{'Common.Size' | translate}}: <strong>{{getSizeUnit(selectedSingleFile.size)}}</strong>
                            </li>
                            <li>{{'Common.Duration' | translate}}: <strong>{{selectedSingleFile.duration}}</strong></li>
                            <li>{{'Common.FileType' | translate}}:
                                <strong>{{getFileTypeName(selectedSingleFile.type)}}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card" *ngIf="selectedFiles && selectedFiles.length > 1">
                <div class="card-body">
                    <ul class="selected-files">
                        <li *ngFor="let file of selectedFiles">
                            <div [ngSwitch]="file.type">
                                <div *ngSwitchCase="fileTypeEnum.Image" class="file-item-selected">
                                    <img class="item" [src]="uploadConfig?.downloadHost + '/crop/120x120/' + file.path" />
                                </div>
                                <div *ngSwitchCase="fileTypeEnum.Pdf" class="file-item-selected">
                                    <i class="fa fa-file-pdf-o fa-lg pdf"></i>
                                </div>
                                <div *ngSwitchCase="fileTypeEnum.Video" class="file-item-selected">
                                    <i class="fa fa-file-video-o fa-lg video"></i>
                                </div>
                                <div *ngSwitchCase="fileTypeEnum.Doc" class="file-item-selected">
                                    <i class="fa fa-file-video-o fa-lg doc"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #tmlAddDirectory>
    <storage-update-folder 
        [directoryToUpdate]="directoryToAdd" 
        (saveResult) = "onDirectorySaved($event)"
        (closeModal) = "onCloseUpdateDirectoryModal()">
    </storage-update-folder>
</ng-template>