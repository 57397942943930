import { INavData } from '@coreui/angular';
import { PermissionGrant } from './core/enums/role.enum';

export interface IExtendNavData extends INavData {
  translateKey?: string;
  children?: IExtendNavData[];
  permissions?: number[];
}

export let navItems: IExtendNavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    permissions: [PermissionGrant.Default]
  },
  {
    title: true,
    name: 'Car info',
    translateKey: 'Nav.CarInfo.Root',
    permissions: [PermissionGrant.Default]
  },
  // {
  //   name: 'Variant',
  //   translateKey: 'Nav.CarInfo.Variant',
  //   icon: 'fa fa-car',
  //   url: '/variant',
  //   permissions: [PermissionGrant.ViewListVariant]
  // },
  {
    name: 'Car info',
    translateKey: 'Nav.CarInfo.CarInfo',
    icon: 'fa fa-cubes',
    url: '/car-info',
    permissions: [PermissionGrant.ViewListCarInfo]
  },
  {
    name: 'Segments',
    translateKey: 'Nav.CarInfo.Segments',
    icon: 'fa fa-car',
    url: '/segments',
    permissions: [PermissionGrant.ViewListSegment]
  },
  {
    title: true,
    name: 'Car specification',
    translateKey: 'Nav.CarSpec.Root',
    permissions: [PermissionGrant.Default]
  },
  {
    name: 'specification structure',
    translateKey: 'Nav.CarSpec.SpecificationStructure',
    icon: 'fa fa-sitemap',
    url: '/car-spec-structure',
    permissions: [PermissionGrant.ConfigSpecStructure],
    children: [
      {
        name: 'property',
        translateKey: 'Nav.CarSpec.CarPropertyGroup',
        url: '/car-spec-structure',
        icon: 'icon-list'
      },
      {
        name: 'Combobox source',
        translateKey: 'Nav.CarSpec.CarPropertyCombobox',
        url: '/car-spec-structure/combobox-source',
        icon: 'icon-note'
      },
    ]
  },
  {
    name: 'Car specification',
    translateKey: 'Nav.CarSpec.CarSpec',
    icon: 'fa fa-car',
    url: '/car-spec',
    permissions: [PermissionGrant.SetCarSpec],
  },
  {
    title: true,
    name: 'New Car',
    translateKey: 'Nav.NewCar',
    permissions: [PermissionGrant.Default],
  },
  {
    name: 'New Car',
    translateKey: 'Nav.NewCarBrand',
    icon: 'fa fa-file-text-o',
    url: '/new-car/type/1',
    permissions: [PermissionGrant.ViewListNewCarArticle],
  },
  {
    name: 'New Car',
    translateKey: 'Nav.NewCarModel',
    icon: 'fa fa-file-text-o',
    url: '/new-car/type/2',
    permissions: [PermissionGrant.ViewListNewCarArticle],
  },
  {
    name: 'New Car',
    translateKey: 'Nav.NewCarVariant',
    icon: 'fa fa-file-text-o',
    url: '/new-car/type/3',
    permissions: [PermissionGrant.ViewListNewCarArticle],
  },
  {
    name: 'New Car',
    translateKey: 'Nav.NewCarBoxSetting',
    icon: 'fa fa-cubes',
    url: '/new-car/box-setting',
    permissions: [PermissionGrant.SetNewCarArticleInBox],
  },
  {
    name: 'New Car',
    translateKey: 'Nav.PromotionPage',
    icon: 'fa fa-gift',
    url: '/newcar-page',
    permissions: [PermissionGrant.ViewListNewCarPage],
  },
  {
    title: true,
    name: 'Promotion',
    translateKey: 'Nav.Promotion',
    permissions: [PermissionGrant.Default],
  },
  {
    name: 'Promotion',
    translateKey: 'Nav.Promotion',
    icon: 'fa fa-gift',
    url: '/promotion',
    permissions: [PermissionGrant.ViewListPromotion],
  },
  {
    name: 'Promotion Hot',
    translateKey: 'Nav.PromotionHot',
    icon: 'fa fa-cubes',
    url: '/promotion/boxhot',
    permissions: [PermissionGrant.SetPromotionInBox],
  },
  {
    name: 'Finance promotion',
    translateKey: 'Nav.FinancePromotion',
    icon: 'fa fa-gift',
    url: '/finance-promotion',
    permissions: [PermissionGrant.ViewListFinancePromotion],
  },
  {
    title: true,
    name: 'Salon',
    translateKey: 'Nav.Salon.Root',
    permissions: [PermissionGrant.Default],
  },
  {
    name: 'Salon',
    translateKey: 'Nav.Salon.Root',
    icon: 'fa fa-users',
    url: '/salon',
    permissions: [PermissionGrant.ViewListSalon],
  },
  {
    name: 'Salon box setting',
    translateKey: 'Nav.Salon.SalonBoxSetting',
    icon: 'fa fa-cubes',
    url: '/salon/box-setting',
    permissions: [PermissionGrant.SetSalonInBox],
  },
  // {
  //   title: true,
  //   name: 'Review & Rating',
  //   translateKey: 'Nav.Review',
  //   permissions: [PermissionGrant.Default],
  // },
  // {
  //   name: 'Reviews',
  //   translateKey: 'Nav.Review',
  //   icon: 'fa fa-comment',
  //   url: '/review',
  //   permissions:[PermissionGrant.Default],
  // },
  // {
  //   title: true,
  //   name: 'News'
  // },

  // {
  //   name: 'Category',
  //   translateKey: 'Nav.Category',
  //   icon: 'icon-folder-alt',
  //   url: '/category'
  // },
  // {
  //   name: 'Author',
  //   translateKey: 'Nav.Author',
  //   icon: 'icon-user',
  //   url: '/author'
  // },
  // {
  //   name: 'Source',
  //   translateKey: 'Nav.Source',
  //   icon: 'fa fa-hashtag',
  //   url: '/article-source'
  // },
  // {
  //   name: 'News',
  //   translateKey: 'Nav.News',
  //   icon: 'icon-book-open',
  //   url: '/news',
  //   children: [
  //     {
  //       name: 'List',
  //       translateKey: 'Nav.List',
  //       url: '/news/list',
  //       icon: 'icon-list'
  //     },
  //     {
  //       name: 'Update',
  //       url: '/news/create',
  //       icon: 'icon-note'
  //     },
  //   ]
  // },
  // {
  //   title: true,
  //   name: 'Other Management',
  //   translateKey: 'Nav.OtherManagement',
  //   permissions: [PermissionGrant.Default],
  // },
  // {
  //   name: 'Color',
  //   translateKey: 'Nav.Color',
  //   icon: 'cil-color-palette',
  //   url: '/color',
  //   permissions: [PermissionGrant.ViewListColor],
  // },
  {
    title: true,
    name: 'System',
    permissions: [PermissionGrant.Default],
  },
  // {
  //   name: 'System',
  //   translateKey: 'Nav.System',
  //   icon: 'fa fa-gears',
  //   url: '/system',
  //   permissions: [PermissionGrant.Default],
  // },
  {
    name: 'System',
    translateKey: 'Nav.User',
    icon: 'fa fa-users',
    url: '/user',
    permissions: [PermissionGrant.ViewListSystemUser],
  },
  // {
  //   name: 'User',
  //   translateKey: 'User',
  //   icon: 'icon-user',
  //   url: '/user'
  // },
  // {
  //   title: true,
  //   name: 'Theme'
  // },
  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  //{
  //  name: 'Base',
  //  url: '/base',
  //  icon: 'icon-puzzle',
  //  permissions: [PermissionGrant.Default],
  //  children: [
  //    {
  //      name: 'Cards',
  //      url: '/base/cards',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Carousels',
  //      url: '/base/carousels',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Collapses',
  //      url: '/base/collapses',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Forms',
  //      url: '/base/forms',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Navbars',
  //      url: '/base/navbars',
  //      icon: 'icon-puzzle'

  //    },
  //    {
  //      name: 'Pagination',
  //      url: '/base/paginations',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Popovers',
  //      url: '/base/popovers',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Progress',
  //      url: '/base/progress',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Switches',
  //      url: '/base/switches',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Tables',
  //      url: '/base/tables',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Tabs',
  //      url: '/base/tabs',
  //      icon: 'icon-puzzle'
  //    },
  //    {
  //      name: 'Tooltips',
  //      url: '/base/tooltips',
  //      icon: 'icon-puzzle'
  //    }
  //  ]
  //},
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Disabled',
  //   url: '/dashboard',
  //   icon: 'icon-ban',
  //   badge: {
  //     variant: 'secondary',
  //     text: 'NEW'
  //   },
  //   attributes: { disabled: true },
  // }
];
