export class SiteManagerModel{
    constructor() {
        this.settingSite = new SettingSite();
    }
    settingSite: SettingSite;
}

export class SettingSite {
    id: number;
    siteName: string;
    domainName: string;
    siteCode: string;
}