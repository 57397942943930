import { isNamedTupleMember } from "typescript";

export enum FileTypeEnum {
    Unknow = -1,
    Image = 1,
    Video = 2,
    Pdf = 3,
    Doc = 4,
    Text = 5
}

export enum PrivacyEnum {
    Private = 0,
    Public = 1
}

export interface UploadConfig {
    serverHost: string;
    downloadHost: string;
    aes_Key: string;
    aes_IV: string;
    token: string;
    projectName: string;
}

export class DirectoryUpdateModel{
    id: number;
    name: string;
    parentId: number;
    privacy: PrivacyEnum;
}


export interface DirectoryModel {
    id: number;
    name: string;
    hasSubDirectories: boolean;
    isExpanded: boolean;
    subDirectories: DirectoryModel[];
    parentId: number;
    privacy: PrivacyEnum;
}

export class FileModel {
    id: number;
    name: string
    path: string;
    directoryId: number;
    directoryName: string;
    type: FileTypeEnum;
    extension: string
    size: number;
    duration: number;
    owner: string;
    createdDate: string;
    modifiedDate: string;
    classList: string[];
}

export class FileDetailModel {
    constructor() {
        this.id = 0;
        this.name = '';
        this.path = '';
        this.directoryId = 0;
    }
    id: number;
    name: string
    path: string;
    directoryId: number;
    directoryName: string;
    type: FileTypeEnum;
    extension: string
    size: number;
    duration: number;
    owner: string;
    createdDate: string;
    modifiedDate: string;
}

export class EventHandleModel {
    constructor(eventName: string = '', params: any = null) {
        this.eventName = eventName;
        this.params = params;
    }
    eventName: string;
    params: any;
}

// Generated by https://quicktype.io

export class PostFileModel {
    constructor(name: string, path: string, directoryId: number, type: number, extension: string, size: number, duration: number, owner: string) {
        this.name = name;
        this.path = path;
        this.directoryId = directoryId;
        this.type = type;
        this.extension = extension;
        this.size = size;
        this.duration = duration;
        this.owner = owner;
    }
    name: string;
    path: string;
    directoryId: number;
    type: number;
    extension: string;
    size: number;
    duration: number;
    owner: string;
}

export interface UploadFileResponse {
    OK: string;
}