import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ReviewStatus, ReviewType } from "../enums/review-status.enum";
import { StringHelper } from "../../helper";
import { BaseListFilter } from "../../shared/models/base-list-filter";
import { ReviewDetailModel, ReviewFilterModel, ReviewInListModel, ReviewRequestCreateModel, ReviewRequestUpdateModel } from "../../shared/models/review.model";

@Injectable({ providedIn: 'root' })
export class ReviewService {

    private restApi: string;
    constructor(private http: HttpClient, @Inject('COMMON_URL') commonUrl: string) {
        this.restApi = `${commonUrl}reviews`;
    }

    filter(request: ReviewFilterModel): Observable<ReviewFilterModel> {
        let parameters = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
                let value = request[key];
                parameters = parameters.set(key, value);
            }
        }
        let requestEnpoint = `${this.restApi}/filter/${request.objectTypeId}`;
        return this.http.get<ReviewFilterModel>(requestEnpoint, { params: parameters });
    }

    getDetail(reviewId: string): Observable<ReviewDetailModel> {
        return this.http.get<ReviewDetailModel>(`${this.restApi}/${reviewId}`);
    }

    create(request: ReviewRequestCreateModel): Observable<string> {
        return this.http.post<string>(this.restApi, request);
    }

    update(reviewId: string, request: ReviewRequestUpdateModel): Observable<string> {
        return this.http.put<string>(`${this.restApi}/${reviewId}`, request);
    }

    changeStatus(model: {id: number, status: ReviewStatus}){
        let patchHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json'});
        let patch = [ 
            { 
               "op": "replace", 
               "path": "/Status", 
               "value": model.status
            } 
        ]
        return this.http.patch(`${this.restApi}/${model.id}`, JSON.stringify(patch), { headers: patchHeaders });
    }
    
    changeShowInUserReviewListState(model: {id: number, state: boolean}){
        let patchHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json'});
        let patch = [ 
            { 
               "op": "replace", 
               "path": "/ShowInUserReviewList", 
               "value": model.state
            } 
        ]
        return this.http.patch(`${this.restApi}/${model.id}`, JSON.stringify(patch), { headers: patchHeaders });
    }
}