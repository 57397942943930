import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActiveStatus } from '../enums/active-status.enum';
import { StringHelper } from '../../helper';
import { BrandInListFilter, BrandListFilter } from '../../shared/models/brand.model';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) {
    this.restApi = `${carInfoUrl}brands`;
  }

  // getListByFilter(filter: BrandListFilter = null): Observable<BrandListFilter> {

  //   // let queryParams: string = null;
  //   // if (filter.keyword != '') queryParams = !!queryParams ? `${queryParams}&keyword=${filter.keyword}`: `?keyword=${filter.keyword}`;
  //   // queryParams = !!queryParams ? `${queryParams}&status=${filter.status}`: `?status=${filter.status}`;
  //   // queryParams = !!queryParams ? `${queryParams}&PageNumber=${filter.currentPage}`: `?PageNumber=${filter.currentPage}`;
  //   // queryParams = !!queryParams ? `${queryParams}&PageSize=${filter.pageSize}`: `?PageSize=${filter.pageSize}`;

  //   let params = new HttpParams();
  //   for (let key in filter) {
  //     if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
  //       params = params.append(key, filter[key]);
  //     }
  //   }
  //   return this.http.get<BrandListFilter>(`${this.restApi}/filter`, {params:  params});
  // }

  getAll(status: ActiveStatus | null = null){
    let params = new HttpParams();
    if(status != null){
      params = params.append('status', `${status}`);
    }
    return this.http.get<BrandInListFilter[]>(`${this.restApi}`, {params:  params});
  }
}
