import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { UtilityHelper } from '../../helper';
import { Permission} from '../../shared/models/user-login.model';
import { IExtendNavData, navItems } from '../../_nav';
import { OIDCAuthService } from '../auth/oidcauth.service';
import { PermissionGrant} from '../enums/role.enum';
@Injectable({
    providedIn: 'root'
})
export class AppSidebarService {
    public tokenDecode;
    // public items$: Observable<IExtendNavData[]>;
    public navItems = navItems;
    public navItemsOrigin = navItems;
    private listPermission: Permission[];
    public getSidebarItemsChangedSubject = new Subject<IExtendNavData[]>();
    logginChanged = this.getSidebarItemsChangedSubject.asObservable();

    constructor(
        private oidcAuthService: OIDCAuthService,
    ) {

    }

    getSideBarItems(): Observable<IExtendNavData[]>{
        let grantedNavItems = UtilityHelper.deepClone(this.navItems);
        let userPermissions = this.oidcAuthService.getUserPermisions();

        // Check quyền
        if (!!userPermissions) {
            grantedNavItems = grantedNavItems.filter(item => {
                let hasPermision = item.permissions == undefined 
                || item.permissions == null 
                || item.permissions.length == 0 
                || item.permissions.includes(PermissionGrant.Default)
                || this.oidcAuthService.hasPermission(item.permissions);
                return hasPermision;
            });
        }

        //Remove các nav title mà không có element
        grantedNavItems = grantedNavItems.filter((value, index, el) => {
            let removeCondition = false;
            if(!!value.title){
                //xóa nếu item tiếp theo cũng là title, hoặc item hiện tại là item cuối của array
                var nextItem = (index < grantedNavItems.length) ? grantedNavItems[index + 1] : null;

                removeCondition = (nextItem != null && !!nextItem.title) || nextItem == null

            }
            return !removeCondition;
        });

        this.getSidebarItemsChangedSubject.next(grantedNavItems);
        return of(grantedNavItems);
    }

    // public async getSidebarItems2(): Promise<IExtendNavData[]> {
    //     const navItemsOrigin = this.navItemsOrigin.map(x => x);
    //     let navItems: IExtendNavData[] = new Array<IExtendNavData>();
        
    //     // Default All Permission
    //     navItems = this.navItems;

    //     this.tokenDecode = await this.authService.decodeToken();

    //     if (this.tokenDecode != null && typeof this.tokenDecode !== 'undefined') {
    //         let arrPermission = JSON.parse("[" + this.tokenDecode.permission + "]");
    //         this.listPermission = arrPermission[0].map(x => new Permission({ id: x.id, pId: x.pId }));

    //         // Check quyền
    //         if (!!this.listPermission) {
    //             navItemsOrigin.forEach(item => {
    //                 if (!item.permissions.includes(PermissionGrant.Default)) {
    //                     let checkPermission = this.listPermission.find(x => item.permissions.includes(x.id));
    //                     if (checkPermission == null || typeof checkPermission == 'undefined') {
    //                         // let indexItem = navItems.indexOf(item);
    //                         let listRemore = navItems.filter(x => x.permissions.includes(item.permissions[0]));
    //                         listRemore.forEach(element => {
    //                             let index = navItems.indexOf(element);
    //                             if (index > -1) {
    //                                 navItems.splice(index, 1);
    //                             }
    //                         });
                            
    //                     }
    //                 }
    //             });
    //         }
    //     }
    //     else {
    //         navItems = new Array<IExtendNavData>();
    //     }

    //     this.getSidebarItemsChangedSubject.next(navItems);

    //     return navItems;
    // }
}