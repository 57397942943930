export enum Unit{
    L = 1,
    hp = 2,
    Nm = 3,
    mm = 4,
    km = 5,
    hours = 6,
    kg = 7,
    inches = 8
}

export enum EngineCapacityUnit{
    l = 1
}

export enum MaxTorqueUnit{
    nm = 1
}

export enum MaxPowerUnit{
    hp = 1
}