import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../helper';
import { VariantDetailModel, VariantFilterModel, VariantModel, VariantUpdateModel } from '../../shared/models/car-info/variant.model';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  restApi: string; 
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) 
  {
    this.restApi = `${carInfoUrl}variants`;
  }

  filter(request: VariantFilterModel): Observable<VariantFilterModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<VariantFilterModel>(`${this.restApi}/filter`, { params: parameters });
  }

  getAll(modelId: number): Observable<VariantModel[]> {
    let params = new HttpParams();
    params = params.append('modelId', `${modelId}`);
    return this.http.get<VariantModel[]>(this.restApi, {params:  params});
  }

  getListForCRUDPromotion(modelId: number): Observable<VariantModel[]> {
    let params = new HttpParams();
    params = params.append('modelId', `${modelId}`);
    return this.http.get<VariantModel[]>(`${this.restApi}/for-crud-promotion`, { params: params });
  }

  create(model: VariantUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  getDetail(id: number|string ) {
    return this.http.get<VariantDetailModel>(`${this.restApi}/${id}`);
  }

  update(model: VariantUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }
}
