import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationResponse } from '../../shared/models/identity.model';
import { ResponseData } from '../../shared/models/responsedata';
import { Constants } from '../../helper/constants/constants';
import { SSOLoginModel } from '../../shared/models/sso-login.model';
import { SettingSite } from '../../shared/models/site-manager.model';
import { ExternalLoginModel, IS4AuthenticationResponse } from '../../shared/models/external-login.model';
import { OIDCAuthService } from '../auth/oidcauth.service';

/**
 * Provides methods to write log records to server.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private restUrl: string;
  private authenUrl = '';
  private refreshTokenUrl = '';
  private revokeTokenUrl = '';

  private currentUserSubject: BehaviorSubject<AuthenticationResponse>;
  public currentUser: Observable<AuthenticationResponse>;
  public settingSite: SettingSite;

  public get currentAuthenticate(){
    const jsonCurrentUser = localStorage.getItem(Constants.identityCurrentUserKey);
    return jsonCurrentUser != null ? JSON.parse(jsonCurrentUser) : null; 
  }

  constructor(
    private http: HttpClient,
    @Inject('COMMON_URL') commonUrl: string,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthenticationResponse>(this.currentAuthenticate);
    this.currentUser = this.currentUserSubject.asObservable();

    this.restUrl = `${commonUrl}authentication`;

    this.authenUrl = `${commonUrl}authentication`;
    this.refreshTokenUrl = `${this.restUrl}/refresh-token`;
    this.revokeTokenUrl = `${this.restUrl}/revoke-token`;
  }

  getCurrentUser(): AuthenticationResponse {
    const jsonCurrentUser = localStorage.getItem(Constants.identityCurrentUserKey);
    return jsonCurrentUser != null ? JSON.parse(jsonCurrentUser) : null;
  }

  getSettingSite(): SettingSite {
    const json = localStorage.getItem(Constants.settingSiteKey);
    this.settingSite = json != null ? JSON.parse(json) : null;
    return this.settingSite;
  }

  login(username: string, password: string): Observable<ResponseData<AuthenticationResponse>> {
    //return this.http.post<AuthenticationResponse>(this.baseUrl, { "Username": username, "Password": password });
    return this.http.post<any>(this.restUrl, { username, password })
      .pipe(map(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(Constants.identityCurrentUserKey, JSON.stringify(response));
        this.currentUserSubject.next(response);

        // FAKE:
        this.settingSite = new SettingSite();
        this.settingSite.id = 1;
        this.settingSite.siteName = 'Oto';
        this.settingSite.siteCode = 'oto';
        this.settingSite.domainName = 'oto.com.vn';
        localStorage.setItem(Constants.settingSiteKey, JSON.stringify(this.settingSite));

        return response;
      }));
  }

  loginSSO(ssoLoginModel: SSOLoginModel) {
    return this.http.post<any>(`${this.restUrl}/sso`, ssoLoginModel)
      .pipe(
        map(response => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(Constants.identityCurrentUserKey, JSON.stringify(response));
          this.currentUserSubject.next(response);
          return response;
        }));
  }
  //#region Method cho login external
  loginFromExternalToken(externalLoginModel: ExternalLoginModel) {
    return this.http.post<any>(`${this.restUrl}/login-external`, externalLoginModel)
      .pipe(
        map((response: IS4AuthenticationResponse) => {
          this.mapAuthenticationResponseToLocalStorage(response);
          return response;
        }));
  }


  refeshTokenForExternalLogin(refreshToken: string ) {
    return this.http.post<any>(`${this.restUrl}/refresh-token-external`, {refreshToken})
    .pipe(
      map((response: IS4AuthenticationResponse) => {
        this.mapAuthenticationResponseToLocalStorage(response);
        return response;
      }));
  }

  mapAuthenticationResponseToLocalStorage(response: IS4AuthenticationResponse){
    localStorage.setItem("id_token", response.identityToken);
    localStorage.setItem("access_token", response.accessToken);
    localStorage.setItem("refresh_token", response.refreshToken);
    localStorage.setItem("granted_scopes", `["${response.scope}"]`);
  }
  //#endregion
  

  logout() {
    var refreshToken: string = this.getCurrentUser() != null ? this.getCurrentUser().refreshToken : '';
    try {
      this.http.post<any>(`${this.restUrl}/revoke-token`, { Token: refreshToken }, { withCredentials: false })
        .subscribe(error => {
          var responseData: ResponseData<object> = error.error;
        }
        );
    }
    catch (ex) {
    }

    //this.currentAuthenticate = null;
    //this.stopRefreshTokenTimer();
    // remove user from local storage to log user out
    this.currentUserSubject.next(null);
    this.clearTokens();
  }

  private storeTokens(data: any) {
    localStorage.setItem(Constants.identityCurrentUserKey, JSON.stringify(data));
  }
  private clearTokens(){
    localStorage.removeItem(Constants.identityCurrentUserKey);
  }

  refreshToken() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json-patch+json'});
    let refreshToken: string = this.currentAuthenticate != null ? this.currentAuthenticate.refreshToken : '';
    return this.http.post<any>(`${this.restUrl}/refresh-token/`, JSON.stringify(refreshToken) , { headers: headers })
      .pipe(
        tap(response => {
          this.storeTokens(response);
          this.currentUserSubject.next(response);
          return response;
        })
      );
  }

  handleRefreshTokenError(returnUrl: string = null){
    this.currentUserSubject.next(null);
    this.clearTokens();
    this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl} });
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentAuthenticate.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
