import { Component, Inject, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { AuthenticationService, NavigationService } from '../core/services';
import { CustomHttpErrorResponse } from '../shared/models/responsedata';
import { SSOLoginModel } from '../shared/models/sso-login.model';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSsoComponent implements OnInit {
  SSOLoginModel: SSOLoginModel;
  errorMsg: string;
  errorTradeId: string;
  isInit: boolean = true;
  showError: boolean = false;
  ssoAccountHasnotLinked: boolean = false; //Biến check nếu tk SSO chưa đc link với tài khoản AP
  returnUrl: string;
  constructor(
    private activeRoute: ActivatedRoute, 
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    @Inject('SSO_LOGIN_ENPOINT') private SSO_LOGIN_ENPOINT: string
  ) { 
    this.SSOLoginModel = new SSOLoginModel();
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
			if(params['data'] != undefined)
			{
        this.SSOLoginModel.token = params['data']; 
        this.loginSSO();
      }
      else{
        this.ssoAccountHasnotLinked = true;
        this.showError = true;
        this.errorMsg = this.translate.instant('SSOLogin.SSOAccountHasnotLinked');
      }
    });
    this.isInit = false;

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || NavigationService.homeLink;
  }

  loginSSO(){
    this.spinner.show('ssoLoginSpinner');
    this.authenticationService.loginSSO(this.SSOLoginModel)
      .pipe(first())
      .subscribe(data => {
        let url = decodeURIComponent(this.returnUrl);
          if (url.indexOf('/') != -1) url = url.substr(1);
          url = '/' + url;
          return location.href = url;
      },
      error => {
        let customErr = new CustomHttpErrorResponse(error);
        this.spinner.hide('ssoLoginSpinner');
        this.showError = true;
        if(customErr.hasTradeId)
        {
          this.errorTradeId = customErr.tradeId;
        }
        this.errorMsg = this.translate.instant('SSOLogin.LoginFailed');
      });
  }

  tryAgain(){
    this.showError = false;
    if(this.ssoAccountHasnotLinked){
      window.location.href= this.SSO_LOGIN_ENPOINT; 
    }
    else{
      this.loginSSO();
    }
  }
}
