import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../helper';
import { BaseListFilter } from '../../shared/models/base-list-filter';
import { FinancePromotionDetailModel, FinancePromotionFilterModel, FinancePromotionUpdateModel } from '../../shared/models/promotion/finance-promotion.model';

@Injectable({
  providedIn: 'root'
})
export class FinancePromotionService {
  private restApi: string;
  constructor(
      private http: HttpClient, 
      @Inject('PROMOTION_URL') promotionUrl: string
  ) {
      this.restApi = `${promotionUrl}finance-promotions`;
  }

  filter(request: FinancePromotionFilterModel): Observable<FinancePromotionFilterModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<FinancePromotionFilterModel>(this.restApi, { params: parameters });
  }

  getDetail(id: number): Observable<FinancePromotionDetailModel> {
    return this.http.get<FinancePromotionDetailModel>(`${this.restApi}/${id}`);
  }

  create(request: FinancePromotionUpdateModel){
      return this.http.post<string>(this.restApi, request);
  }

  update(request: FinancePromotionUpdateModel) {
      return this.http.put<string>(`${this.restApi}/${request.id}`, request);
  }
}
