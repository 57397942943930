import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthenticationService, NavigationService } from '../core/services';
import { AuthenticationResponse } from '../shared/models/identity.model';
import { ResponseData } from '../shared/models/responsedata';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  isssologin: boolean = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private naviService: NavigationService,
    private authenticationService: AuthenticationService,
  ) {
    this.isssologin = environment.isssologin;
    const currentUser: AuthenticationResponse = this.authenticationService.getCurrentUser();
    if (currentUser && currentUser != null && currentUser.id > 0) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    if(this.isssologin){
      window.location.href= environment.ssoLoginEndpoint;
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || NavigationService.homeLink;
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          let url = decodeURIComponent(this.returnUrl);
          if (url.indexOf('/') != -1) url = url.substr(1);
          url = '/' + url;
          return location.href = url;
          //return this.router.navigate([url]);
          //return this.naviService.goToUrl(this.returnUrl);
        },
        error => {
          var responseData: ResponseData<object> = error.error;
          this.error = responseData.message;
          this.loading = false;
        });
  }
}