import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FuelTypeInListModel } from '../../shared/models/car-info/fuel-type.model';

@Injectable({
  providedIn: 'root'
})
export class FuelTypeService {
  restApi: string;
  
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') carInfoUrl: string
  ) {
    this.restApi = `${carInfoUrl}fuel-types`;
  }

  getAll(): Observable<FuelTypeInListModel[]> {
    return this.http.get<FuelTypeInListModel[]>(`${this.restApi}`);
  }
}
