import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../helper';
import { CarColorModel } from '../../shared/models/car-info/car-color.model';
import { ColorFilterModel, ColorUpdateModel } from '../../shared/models/color.model';

@Injectable({
  providedIn: 'root'
})
export class CarColorService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) 
  {
    this.restApi = `${this.carInfoUrl}car-color`;
  }
  
  filter(filter: ColorFilterModel): Observable<ColorFilterModel> {
    let params = new HttpParams();
    for (let key in filter) {
      if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
        params = params.append(key, filter[key]);
      }
    }
    return this.http.get<ColorFilterModel>(`${this.restApi}/filter`, {params:  params});
  }

  getAll(): Observable<CarColorModel[]> {
    return this.http.get<CarColorModel[]>(`${this.restApi}/getall`);
  }

  create(model: ColorUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: ColorUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }
}
