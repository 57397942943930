import { Injectable } from '@angular/core';
import { RoutePathBuilder } from '@ngspot/route-path-builder';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'any' })
export class AppRoutes extends RoutePathBuilder {
  public newCars = this.childRoutes(NavigationService.newCar, NewCarRoutes);
}


@Injectable({ providedIn: 'any' })
export class NewCarRoutes extends RoutePathBuilder {
  create() {
    return this.url('create');
  }
  edit(newCarId?: string) {
    return this.urlFromCommands([newCarId], );
  }
}