export enum ReviewStatus {
    /// <summary>
    /// Trạng thái mặc định khi đánh giá được thêm
    /// </summary>
    Unapproved = 1,
    /// <summary>
    /// Review/reply đã được duyệt
    /// </summary>
    Approved = 2,
    /// <summary>
    /// Review/reply bị từ chối duyệt
    /// </summary>
    Disapproved = 3,
    /// <summary>
    /// Đã bị xóa
    /// </summary>
    Deleted = 4
}

export enum ReviewType {
    Review = 1,
    Reply = 2
}