import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Provides methods to perform application navigation.
 */
@Injectable()
export class NavigationService {

    constructor(private router: Router) { }

    static readonly createPath = 'create';
    static readonly updatePath = 'update/:id';
    static readonly updatePathNoRouteParams = 'update';
    static readonly detailPath = 'detail/:id';
    static readonly typePath = 'type/:type';
    static readonly boxSetting = 'box-setting';

    static readonly specStructure = 'car-spec-structure';
    static readonly specStructureCombobox = 'combobox-source';
    static readonly specStructureList = 'list';
    static readonly specStructureCreate = 'create';
    static readonly specStructureUpdate = 'update/:id';

    static readonly carSpec = 'car-spec';
    static readonly carSpecCreate = '';

    static readonly newCar = 'new-car';
    static readonly newCarArticle = 'new-car/type/:type';
    static readonly newCarUpdate = 'new-car/update/:type/:id';
    static readonly newCarCreate = 'new-car/create/:type';
    static readonly newCarBoxSetting = 'box-setting';
    static readonly newCarCreatePath = 'create/:type';
    static readonly newCarUpdatePath = 'update/:type/:id';

    static readonly promotion = 'promotion';
    static readonly promotionBoxHot = 'boxhot';
    static readonly promotionDetail = 'promotion/detail/:id';
    static readonly promotionUpdate = 'promotion/update/:id';

    static readonly salon = 'salon';
    static readonly salonDetail = 'salon/detail/:id';
    static readonly salonUpdate = 'salon/update/:id';
    static readonly salonUpdatePath = 'update/:id';
    static readonly salonSetUsers = 'salon/set-user';
    static readonly salonSetUsersPath = 'set-user/:id';

    static readonly newcarPage = 'newcar-page';
    static readonly newcarPageCreate = 'newcar-page/create';
    static readonly newcarPageUpdate = 'newcar-page/update/:id';

    static readonly financePromo = 'finance-promotion';

    static readonly managerUser = 'user';
    static readonly managerUserList = 'list';
    static readonly managerUserDetail = 'user/:id';
    static readonly managerUserSetPermission = 'set-permission/:id';

    static readonly managerCategory = "category";
    static readonly managerCategoryList = "list";
    static readonly managerCategoryDetail = "detail";
    static readonly managerCategoryCreate = "create";
    static readonly managerCategoryUpdate = "update/:id";

    static readonly managerReview = "review";
    static readonly managerReviewList = "list";
    static readonly managerReviewDetail = "detail";
    static readonly managerReviewCreate = "create";
    static readonly managerReviewUpdate = "update/:id";

    static readonly managerAuthor = "author";
    static readonly managerAuthorList = "list";
    static readonly managerAuthorDetail = "detail";
    static readonly managerAuthorCreate = "create";
    static readonly managerAuthorUpdate = "update/:id";

    static readonly managerArticleSource = "article-source";
    static readonly managerArticleSourceList = "list";


    static readonly managerNews = "news";
    static readonly managerNewsList = "list";
    static readonly managerNewsDetail = "detail";
    static readonly managerNewsCreate = "create";
    static readonly managerNewsUpdate = "update/:id";

    static readonly system = "system";

    static readonly carInfo = "car-info";

    static readonly variant = "variant";

    static readonly segments = "segments";

    static readonly color = "color";

    static readonly filemanager = "filemanager";

    static readonly uploadmanager = "uploadmanager";

    static readonly loginRoute = 'login';
    static readonly loginSSORoute = 'loginsso';
    static readonly loginExternalRoute = 'login-external';
    static readonly homeLink = '/';
    static readonly dashboardLink = '/dashboard';
    static readonly notfoundLink = '404';
    static readonly errorLink = '503';

    goToLogin() {
        this.router.navigate([`/${NavigationService.loginRoute}`]);
    }

    //#region Car specification structure

    goToCarStructureGroupUpdate(id: number) {
        let route = `/${NavigationService.specStructure}/${NavigationService.specStructureCreate}`;
        if (id && id > 0) {
            route = `/${NavigationService.specStructure}/${NavigationService.specStructureUpdate.replace(':id', String(id))}`;
        }
        this.router.navigate([route]);
    }

    goToCarSpecStructureList() {
        const route = `/${NavigationService.specStructure}/${NavigationService.specStructureList}`;
        this.router.navigate([route]);
    }

    //#endregion

    //#region Car spec
    gotoCarSpecList() {
        this.router.navigate([`/${NavigationService.carSpec}`]);
    }
    //#endregion

    //#region Category navigation
    goToCategoryList() {
        const route = `/${NavigationService.managerCategory}/${NavigationService.managerCategoryList}`;
        this.router.navigate([route]);
    }

    goToCategoryUpdate(id: number) {
        let route = `/${NavigationService.managerCategory}/${NavigationService.managerCategoryCreate}`;
        if (id && id > 0) {
            route = `/${NavigationService.managerCategory}/${NavigationService.managerCategoryUpdate.replace(':id', String(id))}`;
        }
        this.router.navigate([route]);
    }

    //#endregion

    //#region Author navigations
    goToAuthorList() {
        const route = `/${NavigationService.managerAuthor}/${NavigationService.managerAuthorList}`;
        this.router.navigate([route]);
    }

    goToAuthorUpdate(id: number) {
        let route = `/${NavigationService.managerAuthor}/${NavigationService.managerAuthorCreate}`;
        if (id && id > 0) {
            route = `/${NavigationService.managerAuthor}/${NavigationService.managerAuthorUpdate.replace(':id', String(id))}`;
        }
        this.router.navigate([route]);
    }
    //#endregion

    //#region User navigations

    goToUserList() {
        const route = `/${NavigationService.managerUser}/${NavigationService.managerUserList}`;
        this.router.navigate([route]);
    }

    goToSetPermission(userId: number) {
        let route = `/${NavigationService.managerUser}/${NavigationService.managerUserSetPermission.replace(':id', String(userId))}`;
        this.router.navigate([route]);
    }

    //#endregion

    goToNewsList() {
        const route = `/${NavigationService.managerNews}/${NavigationService.managerNewsList}`;
        this.router.navigate([route]);
    }

    goToNewsUpdate(id: number) {
        let route = `/${NavigationService.managerNews}/${NavigationService.managerNewsCreate}`;
        if (id && id > 0) {
            route = `/${NavigationService.managerNews}/${NavigationService.managerNewsUpdate.replace(':id', String(id))}`;
        }
        this.router.navigate([route]);
    }

    // goToUserList(page: number) {
    //     const route = `/${NavigationService.managerUserList.replace(':page', String(page))}`;
    //     this.router.navigate([route]);
    // }

    goToUserDetail(id: number) {
        const route = `/${NavigationService.managerUserDetail.replace(':id', String(id))}`;
        this.router.navigate([route]);
    }

    goToHomePage() {
        this.router.navigate([`${NavigationService.homeLink}`]);
    }

    goToUrl(url: string) {
        url = decodeURIComponent(url);
        if (url.indexOf('/') != -1) url = url.substr(1);
        url = '/' + url;
        return this.router.navigate([`${url}`]);
    }
}
