// environment.common.ts

export const commonEnv = {
    newCarApi: {
        href: 'https://localhost:5005',//Apply only in development environment, other environments are using API Gateway
        gateWaySurfix: 'newcar',
        version: 'v1'
    },
    commonApi: {
        href: 'https://localhost:5004',
        gateWaySurfix: 'common',
        version: 'v1'
    },
    carInfoApi: {
        href: 'https://localhost:5005',
        gateWaySurfix: 'carinfo',
        version: 'v1'
    },
    promotionApi: {
        href: 'https://localhost:5007',
        gateWaySurfix: 'promotion',
        version: 'v1'
    },
    salonApi: {
        href: 'https://localhost:5008',
        gateWaySurfix: 'salon',
        version: 'v1'
    },
    feedback: {
        email: "dungqd@daivietgroup.net.vn",
        phone: '0978 399 902'
    },
    isssologin: false,
    ssoLoginEndpoint: 'http://localhost:49589/Account/logincallback?returnUrl=http://localhost:4200/loginsso&isAPNewCore=true',
    ssoLogoutEndpoint: 'http://localhost:7777/Account/logoutcallback?returnUrl=http://localhost:4200/loginsso&isAPNewCore=true',
};
  