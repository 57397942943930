import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Permission } from '../../shared/models/user-login.model';
import { PermissionGrant } from '../enums/role.enum';
import { authCodeFlowConfig } from './auth-code-flow-config';

@Injectable({
  providedIn: 'root'
})
export class OIDCAuthService {
  public userPermissions: Permission[] = null;
  public isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public helper = new JwtHelperService();
  
  constructor(
    private oauthService: OAuthService,
    private router: Router,
  ) { 
    this.configOIDC();
    this.oauthService.events .subscribe(event => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    // this.oauthService.events
    //   .pipe(filter(e => ['token_received'].includes(e.type)))
    //   .subscribe(e => this.oauthService.loadUserProfile());
  }

  configOIDC(){
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      this.isDoneLoadingSubject$.next(true);
    })
    .catch(() => this.isDoneLoadingSubject$.next(true));
    this.oauthService.setupAutomaticSilentRefresh();//Set auto refresh token
  }

  login(targetUrl?: string){
    this.oauthService.initCodeFlow();
  }


  logout(){
    // this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
  }

  //Useful helper methods

  public hasPermissionOnRoute$(route: ActivatedRouteSnapshot) : Observable<boolean> {
    let routePermissions = (!!route.data?.permissions ? route.data?.permissions : []) as PermissionGrant[];
    let hasPermision = routePermissions == undefined
      || routePermissions == null
      || routePermissions.length == 0
      || routePermissions.includes(PermissionGrant.Default)
      || this.hasPermission(routePermissions);
    return of(hasPermision);
  }

  //Trả về các điều kiện để access route, bao gồm đã đăng nhập (isAuthenticated) và có chứa quyền (hasPermission)
  public accessRouteConditions$(route: ActivatedRouteSnapshot): Observable<boolean[]>
  {
    return combineLatest([
      this.isAuthenticated$,
      this.hasPermissionOnRoute$(route)
    ]).pipe(
      map(([isAuthenticated, hasPermission]) => [isAuthenticated, hasPermission]),
      distinctUntilChanged()
    );
  };

  public getUserPermisions(): Permission[]{
    //T-TEMP, save userPermissions cho lần call sau
    let accessToken = this.oauthService.getAccessToken();
    let decodeToken = this.helper.decodeToken(accessToken);
    let permissionsClaim = !!decodeToken ? decodeToken.permission : null;
    this.userPermissions = (!!permissionsClaim ? JSON.parse(permissionsClaim): []) as Permission[];
    return this.userPermissions;
  }

  public hasPermission(requirePermission: PermissionGrant[]){
    let userPermission = this.getUserPermisions();
    if(userPermission == undefined || userPermission == null || userPermission.length == 0)
    {
        return false;
    }
    let hasPermision = false;
    for (let i = 0; i < requirePermission.length; i++) {
        if(userPermission.some(up => up.id == requirePermission[i])){
            hasPermision = true;
            break;
        }
    }
    return hasPermision;
  }
}
