import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewCarBoxDetailItem, NewCarBoxSettingModel, NewCarBoxSettingUpdateModel } from '../../shared/models/new-car/new-car-box-setting.model';
import { NewCarBoxInListModel } from '../../shared/models/new-car/new-car-box.model';

@Injectable({
  providedIn: 'root'
})
export class NewCarBoxService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('NEWCARS_URL') private newCarUrl: string
  ) {
    this.restApi = `${this.newCarUrl}newcar-box`;
  }

  getAll(): Observable<NewCarBoxInListModel[]>{
    return this.http.get<NewCarBoxInListModel[]>(`${this.restApi}`);
  }

  setDetail(model: NewCarBoxSettingUpdateModel){
    return this.http.post(`${this.restApi}/${model.newCarBoxId}/set-details`, model.items); 
  }

  getListNewCarBoxDetail(newCarBoxId: number){
    return this.http.get<NewCarBoxSettingModel>(`${this.restApi}/box-setting-detail/${newCarBoxId}`);
  }
}
