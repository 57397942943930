import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, NavigationService } from '../../core/services';
import { SSOLoginModel } from '../../shared/models/sso-login.model';
import { first } from 'rxjs/operators';
import { ExternalLoginModel } from '../../shared/models/external-login.model';
import { CustomHttpErrorResponse } from '../../shared/models/responsedata';
import { OIDCAuthService } from '../../core/auth/oidcauth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {
  externalToken: string;
  SSOLoginModel: SSOLoginModel;
  externalLoginModel: ExternalLoginModel;
  loggedInFailedLabel: string;
  errorMsg: string = null;
  errorTradeId: string;
  isInit: boolean = true;
  showError: boolean = false;
  ssoAccountHasnotLinked: boolean = false; //Biến check nếu tk SSO chưa đc link với tài khoản AP
  redirectPath: string;
  constructor(
    private activeRoute: ActivatedRoute, 
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private oidcAuthService: OIDCAuthService,
    private oauthService: OAuthService,
    @Inject('SSO_LOGIN_ENPOINT') private SSO_LOGIN_ENPOINT: string
  ) { 
    this.SSOLoginModel = new SSOLoginModel();
    this.externalLoginModel = new ExternalLoginModel();
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
			if(params['credential'] != undefined)
			{
        this.externalLoginModel.CredentialToken = params['credential'];
        // this.SSOLoginModel.token = params['data']; 
        this.loginFromExternalToken();
      }
      else{
        this.ssoAccountHasnotLinked = true;
        this.showError = true;
        this.loggedInFailedLabel = this.translate.instant('SSOLogin.SSOAccountHasnotLinked');
      }
    });
    this.isInit = false;

    // get return url from route parameters or default to '/'
    this.redirectPath = this.route.snapshot.queryParams['redirectPath'] || NavigationService.homeLink;
  }

  loginFromExternalToken(){
    this.spinner.show('externalLoginSpinner');
    this.authenticationService.loginFromExternalToken(this.externalLoginModel)
      .pipe(first())
      .subscribe(data => {
        this.oidcAuthService.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        let url = decodeURIComponent(this.redirectPath);
          if (url.indexOf('/') != -1) url = url.substr(1);
          url = '/' + url;
          return location.href = url;
      },
      error => {
        let customErr = new CustomHttpErrorResponse(error);
        this.spinner.hide('externalLoginSpinner');
        this.showError = true;
        if(customErr.hasTradeId)
        {
          this.errorTradeId = customErr.tradeId;
        }

        if (customErr.hasAPICustomError) {
          this.errorMsg = customErr.apiCustomError[0];
        }

        this.loggedInFailedLabel = this.translate.instant('ExternalLogin.LoginFailed');
      });
  }
  tryAgain(){
    this.errorMsg = null;
    this.showError = false;
    this.loginFromExternalToken();
    // if(this.ssoAccountHasnotLinked){
    //   window.location.href= this.SSO_LOGIN_ENPOINT; 
    // }
    // else{
    //   this.loginSSO();
    // }
  }
}
