import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppSidebarService } from '../../core/services/app-sidebar.service'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Constants } from '../../helper';
import { INavData } from '@coreui/angular';
import { environment } from '../../../environments/environment';
import { AuthenticationService, NavigationService } from '../../core/services';
import { Observable } from 'rxjs';
import { IExtendNavData } from '../../_nav';
import { OIDCAuthService } from '../../core/auth/oidcauth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { SettingSite } from '../../shared/models/site-manager.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, OnChanges {
  public sidebarMinimized = false;
  public navItems: IExtendNavData[];
  public navItemSidebar;
  public get feedBackInfo() {
    return environment.feedback;
  }
  isLoggedIn: boolean = false;

  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;

  public settingSite: SettingSite;
  
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private appSideBarService: AppSidebarService,
    private oidcAuthService: OIDCAuthService,
    private oauthService: OAuthService,
  ) {

    this.isAuthenticated$ = this.oidcAuthService.isAuthenticated$;
    this.isDoneLoading$ = this.oidcAuthService.isAuthenticated$;

    this.isAuthenticated$.subscribe(res=> {
      this.getNavItems();
      this.appSideBarService.getSidebarItemsChangedSubject.subscribe(sideBar => {
        this.navItemSidebar = sideBar;
      });
        
      //T-TEMP
      // FAKE:
      this.settingSite = new SettingSite();
      this.settingSite.id = 1;
      this.settingSite.siteName = 'Oto';
      this.settingSite.siteCode = 'oto';
      this.settingSite.domainName = 'oto.com.vn';
      localStorage.setItem(Constants.settingSiteKey, JSON.stringify(this.settingSite));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.reUpdateLang();
    //this.navItems = navItems.map(items => {this.translateNav(items); return items; });
  }

  ngOnInit(): void {
    this.getNavItems();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const translatedNavs = this.navItemSidebar.map(items => { this.translateNav(items); return items; });
      this.navItems = [];//Chỗ này phải empty và clone lại, vì angular ko detech đc array đã thay đổi
      translatedNavs.forEach(val => this.navItems.push(Object.assign({}, val)));
    });

  }

  getNavItems(){
    this.appSideBarService.getSideBarItems().subscribe(navItems => {
      this.navItems = navItems.map(items => 
        { 
          this.translateNav(items); 
          return items; 
        });
    })
  }

  translateNav(item): void {
    if ('translateKey' in item) {
      const nameTranslated = this.translate.instant(`${item.translateKey}`);
      item.name = nameTranslated;
    }
    if (item.children && item.children.length > 0) {
      item.children.map((child: any) => this.translateNav(child));
    }
  }

  reUpdateLang() {
    this.navItems.forEach((item) => {
      if (item.name) {
        const langName = this.translate.get(item.name);
        if (langName) {
          item.name = this.translate.instant(item.name);
        }
      }
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout($event: any) {
    this.authenticationService.logout();
    this.router.navigate([NavigationService.homeLink]);
    localStorage.removeItem(Constants.storageCustomerKey);
    localStorage.removeItem(Constants.storageDomainKey);
  }
  //----------------------------------------------------------------
  loginOIDC() {
    this.oidcAuthService.login();
  }

  logoutOIDC() {
    this.oidcAuthService.logout();
  }

  getAccessToken(){
    
    //console.log(this.accessToken());
  }

  accessToken() { 
    return this.oauthService.getAccessToken(); 
  }

  getAccessTokenExpiration(){
    // console.log(`${this.oauthService.getAccessTokenExpiration()} - ${this.oauthService.hasValidAccessToken()}`);
    // console.log(`${this.oauthService.getIdTokenExpiration()} - ${this.oauthService.hasValidIdToken()}`);
  }
}
