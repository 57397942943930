import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as alertify from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor(
    //   private translate: TranslateService
  ) { }

  


  confirm(msg: string, header: string, okCallback, cancelCallback = null, option: any = {}){
    let title = header ?? '<span><i class="fa fa-question-circle"></i></span> Confirm'
    alertify.confirm(msg, (e: any)=>{
        if(e){
          okCallback();
        }
        else{
            
        }
      }, ()=> {
        if(cancelCallback){
            cancelCallback();
        }
      })
      .set({...option, title:title });
  }

  confirmLables(message: string, header: string, lableOption: any, okCallback: ()=> any){
    alertify.confirm(message, (e: any)=>{
      if(e){
        okCallback();
      }
      else{

      }
    }).set({title:header}).set('labels', lableOption)
  }

  errorPopup(message: string, callback: ()=> any){
    let header = `<span><i class="fa fa-times"></i></span> Error`;
    alertify.alert(message, function(){
      if(callback != null)
      {
        callback();
      }
      
    }).set({title:header})
  }

  success(message: string){
    alertify.success(message);
  }
  error(message: string){
    alertify.error(message);
  }
  warning(message: string){
    alertify.warning(message);
  }
  message(message: string){
    alertify.message(message);
  }
}
