import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorUpdateModel, AuthorListModel, AuthorDetailModel, AuthorInListModel } from '../../shared/models/author.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorService{
    restApi: string;

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) 
    {
        this.restApi = `${commonUrl}authors`;
    }

    getList(filter: AuthorListModel): Observable<AuthorListModel> {
        let queryParams: string = null;
        let status = filter.status == null ? 0 : filter.status;
        if (filter.keyword != '') queryParams = !!queryParams ? `${queryParams}&keyword=${filter.keyword}`: `?keyword=${filter.keyword}`;
        queryParams = !!queryParams ? `${queryParams}&Status=${status}`: `?Status=${status}`;
        queryParams = !!queryParams ? `${queryParams}&PageNumber=${filter.pageNumber}`: `?PageNumber=${filter.pageNumber}`;
        queryParams = !!queryParams ? `${queryParams}&PageSize=${filter.pageSize}`: `?PageSize=${filter.pageSize}`;

        return this.http.get<AuthorListModel>(`${this.restApi}${queryParams}`);
    }

    getAll(){
        return this.http.get<AuthorInListModel[]>(`${this.restApi}/all`);
    }
    
    getDetail(id: number) {
        return this.http.get<AuthorDetailModel>(`${this.restApi}/${id}`);
    }

    create(model: AuthorUpdateModel) {
        return this.http.post(this.restApi, model);
    }

    update(model: AuthorUpdateModel) {
        return this.http.put(this.restApi, model);
        // .pipe(
        //     map(res=>{
        //         console.log(res);
        //     })
        // );
    }

    changeStatus(model: {id: number, status: number}){
        let patchHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json'});
        let patch = [ 
            { 
               "op": "replace", 
               "path": "/Status", 
               "value": model.status
            } 
        ]
        return this.http.patch(`${this.restApi}/${model.id}`, JSON.stringify(patch), { headers: patchHeaders });
    }
}