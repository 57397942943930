import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { StringHelper } from "./string.helper";

export class DateTimeHelper {
    public static toString(ngbDate: NgbDate, format: string = 'YYYY-MM-DD'){
        let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        return moment(date).format(format);
    }

    public static toNbgDate(value: string): NgbDate{
        if(StringHelper.isNullOrEmpty(value))
            return null;
        let date = new Date(value);
        return NgbDate.from({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() });
    }
}