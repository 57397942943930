import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegionInListModel } from '../../shared/models/region.model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('COMMON_URL') commonUrl: string
  )
  {
      this.restApi = `${commonUrl}regions`;
  }

  getAll(): Observable<RegionInListModel[]> {
    return this.http.get<RegionInListModel[]>(`${this.restApi}`);
  }
}
