import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActiveStatus } from '../enums/active-status.enum';
import { CarPriceOptionType } from '../enums/car-price-option.enum';
import { CurrencyUnit } from '../enums/currency-unit.enum';
import { CarImageType } from '../enums/new-car-image-type.enum';
import { StringHelper } from '../../helper';
import { CarInfoDetailModel, CarInfoFilterModel, CarInfoForCreatePromotionModel, CarInfoInListModel, CarInfoUpdateModel } from '../../shared/models/car-info/car-info.model';
import { KeyValuePair } from '../../shared/models/key-value.model';

@Injectable({
  providedIn: 'root'
})
export class CarInfoService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) 
  {
    this.restApi = `${this.carInfoUrl}car-info`;
  }

  filter(request: CarInfoFilterModel): Observable<CarInfoFilterModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<CarInfoFilterModel>(`${this.restApi}/filter`, { params: parameters });
  }

  getListKeyValuePareByModel(modelId: number, activeStatus: ActiveStatus = ActiveStatus.All): Observable<KeyValuePair[]> {
    return of([
      {key: 1, value: 'V'}//T-TEMP
    ]);
    return this.http.get<CarInfoInListModel[]>(`${this.restApi}/${modelId}/carinfos?status=${activeStatus}`)
      .pipe(
        map(x => {
          return x.map(item => new KeyValuePair({key: item.id, value: item.name}));
        })
      );
  }
  getAll(variantId: number, activeStatus: ActiveStatus = ActiveStatus.All): Observable<CarInfoInListModel[]> {
    let params = new HttpParams();
    params = params.append('variantId', `${variantId}`);
    return this.http.get<CarInfoInListModel[]>(`${this.restApi}/${variantId}/carinfos?status=${activeStatus}`);
  }

  getDetail(id: number|string ): Observable<CarInfoDetailModel> {
    return this.http.get<CarInfoDetailModel>(`${this.restApi}/${id}`);
  }

  create(model: CarInfoUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: CarInfoUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }
  
  getInfoForCreatePromotion(id: number|string ): Observable<CarInfoForCreatePromotionModel> {
    return this.http.get<CarInfoForCreatePromotionModel>(`${this.restApi}/info-for-create-promotion?id=${id}`);
  }
}
