import { commonEnv } from "../environment.common";

const env = {
  production: false,
  development: false,
  root: 'https://newcore-cms.dvg-lc.com',
  authorityDomain: 'https://is4.dvg-lc.com',
  base: {
    apiGateWay: 'https://cms-awg.dvg-lc.com',
    version: 'v1'
  },
  downloadHost: 'https://img.philkotse.com',
  autoGenPromotionTitle: true
};

export const environment = { ...commonEnv, ...env };
// export const environment = {
//     production: false,
//     development: false,
//     root: 'https://newcore-cms.dvg-lc.com',
//     authorityDomain: 'https://is4.dvg-lc.com',
//     base: {
//       apiGateWay: 'https://cms-awg.dvg-lc.com',
//       version: 'v1'
//     },
//     newCarApi: {
//       href: '',
//       gateWaySurfix: 'newcar',
//       version: 'v1'
//     },
//     commonApi: {
//       href: '',
//       gateWaySurfix: 'common',
//       version: 'v1'
//     },
//     carInfoApi: {
//       href: '',
//       gateWaySurfix: 'carinfo',
//       version: 'v1'
//     },
//     promotionApi: {
//       href: '',
//       gateWaySurfix: 'promotion',
//       version: 'v1'
//     },
//     salonApi: {
//       href: '',
//       gateWaySurfix: 'salon',
//       version: 'v1'
//     },
//     isssologin: false,
//     ssoLoginEndpoint: 'http://localhost:49589/Account/logincallback?returnUrl=http://localhost:4200/loginsso&isAPNewCore=true',
//     ssoLogoutEndpoint: 'http://localhost:7777/Account/logoutcallback?returnUrl=http://localhost:4200/loginsso&isAPNewCore=true',
//     feedback: {
//       email: "dungqd@daivietgroup.net.vn",
//       phone: '0978 399 902'
//     },
//     //downloadHost: 'https://img.cintamobil.com'//Cinta
//     //downloadHost: 'https://img1.oto.com.vn'//Oto
//     downloadHost: 'https://img.philkotse.com',//PHIL
//     autoGenPromotionTitle: true
// };
