export enum BlockContentType {
    Normal = 1, //Thường
    Highlight = 2,
    Advantage = 3,
    Disadvantage = 4, //Nhược điểm
    Overview = 5, //Mô tả
    ColorBoxText= 6, // Mô tả box màu xe
    ComparisonBoxText = 7, //  Mô tả box so sánh
    Block = 8 // ArticleBlock
}
