import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActiveStatus } from '../enums/active-status.enum';
import { CarPropertyType } from '../enums/car-property-type.enum';
import { Unit } from '../enums/unit.enum';
import { StringHelper } from '../../helper';
import { CarPropertyGroupDetailModel, CarPropertyGroupFilter, CarPropertyGroupInListModel, CarPropertyGroupUpdateModel } from '../../shared/models/car-property-group.model';

@Injectable({
  providedIn: 'root'
})
export class CarPropertyGroupService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  ) 
  {
    this.restApi = `${this.carInfoUrl}car-groups`;
  }

  getList(filter: CarPropertyGroupFilter): Observable<CarPropertyGroupInListModel[]> {
    let params = new HttpParams();
    for (let key in filter) {
      if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
        params = params.append(key, filter[key]);
      }
    }
    return this.http.get<CarPropertyGroupInListModel[]>(`${this.restApi}/filter`, {params:  params});
  }

  getDetail(id: number) {
    return this.http.get<CarPropertyGroupDetailModel>(`${this.restApi}/${id}`);
  }

  create(model: CarPropertyGroupUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: CarPropertyGroupUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }

  //Get list group with properties

  getStructure(): Observable<CarPropertyGroupDetailModel[]> {
    return this.http.get<CarPropertyGroupDetailModel[]>(`${this.restApi}`);
  }

  delete(id: number){
    return this.http.delete(`${this.restApi}/${id}`);
  }
}
