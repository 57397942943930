import { KeyValuePair } from "../shared/models/key-value.model";

export class EnumHelper{
    ///valueWithSpace: nếu true, sẽ tách space giữa các từ (detect bằng chữ cái viết hoa) trong key enum
    public static enumToListKeyValuePair<E>(Enum: any, valueWithSpace: boolean = false): KeyValuePair[]{ //Enum: {[keyof E]: E}
        const result: KeyValuePair[] = [];
        Object.keys(Enum).filter(k => {
            if(typeof Enum[k as any] === "number")
            {
                let value = valueWithSpace == true ? k.replace(/([A-Z])/g, ' $1') : k;
                result.push({key: Enum[k as any], value: value})
            }     
        });
        return result;
    }
}