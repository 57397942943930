import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap, map } from 'rxjs/operators';
import { OIDCAuthService } from './oidcauth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private oidcAuthService: OIDCAuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.oidcAuthService.isDoneLoading$.pipe(
            filter(isDone => isDone),
            switchMap(_ => this.oidcAuthService.accessRouteConditions$(route)),
            map(([isAuthenticated, hasPermission]) => {
                //return true;
                if(isAuthenticated && hasPermission)
                {
                    //Đã login và có quyền => can active
                    return true;
                }
                else if(isAuthenticated && !hasPermission)
                {
                    //Đã login nhưng ko có quyền => redirect về home (hoặc forbidden page) + alert cho user
                    this.toastr.error(this.translate.instant('Common.ForbiddenMsg'));
                    this.router.navigate(['/']);
                    return false;
                }
                else{
                    //TH còn lại (chưa login )
                    this.oidcAuthService.login();
                }
            })
        );
    }
}