<app-header [navbarBrandRouterLink]="['/']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo.svg', width: 89, height: 25, alt: 'CoreUI Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/sygnet.svg', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="'lg'">
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li>
  </ul> -->
  <ul class="nav navbar-nav ml-auto">
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-list"></i></a>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-location-pin"></i></a>
    </li> -->
    <!-- <li class="nav-item" placement="bottom right">
      <app-select-language></app-select-language>
    </li> -->
    
    <li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="isAuthenticated$ | async">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <!-- <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span
            class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span
            class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span
            class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span
            class="badge badge-warning">42</span></a> -->
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span
            class="badge badge-primary">42</span></a>
        <div class="divider"></div> 
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>-->
        <a  class="dropdown-item" href="javascript:;" (click)="logoutOIDC($event)"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <ngx-spinner [fullScreen]="true" type="ball-spin-clockwise" size="default" bdColor="rgba(255,255,255,0)" color="#DA1A21"></ngx-spinner>
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div id="custom-sidebar-minimizer" class="d-none" role="button">
      <span data-css-15b13by="">
        <svg class="nav-toggle-btn_nav-toggle-btn__svg__3I6KQ" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
          <path d="M2 17.02h17.33v2H2zM2 11.06h15.49v2H2zM18.55 2.52L17.2 3.98l1.16 1.08H2v2h16.4L17.35 8.2l1.46 1.37L22.2 5.9l-3.64-3.39zm.78 3.54v-.08l.04.04-.04.04z"></path>
        </svg>
        <!-- <svg aria-label="menu icon" width="24px" height="24px" viewBox="0 0 24 24" role="img">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22 11.5v1a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM2.5 17h18.98a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5zm0-12h18.977a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5z"></path>
        </svg> -->
      </span>
    
    </div>
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <!-- <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" href="#"><i class="icon-speech"></i></a>
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li> -->
    </cui-breadcrumb>
    <div class="container-fluid">
      <!--<div class="test-auth">
        <button *ngIf="!(isAuthenticated$ | async)" (click)="loginOIDC()">Login</button>
        <button (click)="getAccessToken()">Get Access Token</button>
        <button (click)="getAccessTokenExpiration()">Get Access Token Expiration</button>
      </div>-->
      <router-outlet>
        <!-- <file-manager></file-manager> -->
      </router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <span>Powered by &copy; 2021 DVG.</span>
  <span class="ml-auto">
    <app-select-language></app-select-language>
  </span>
  <span class="ml-auto">
    <strong>Send feedback:</strong> <a href="mailto:{{ feedBackInfo.email }}">Email</a> - Phone: {{ feedBackInfo.phone }}
  </span>
</app-footer>
