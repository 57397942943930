import { SalonForUpdateModel, SalonForCreateModel } from './../../shared/models/salon.model';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StringHelper } from "../../helper";
import { BaseListFilter } from "../../shared/models/base-list-filter";
import { FilterSalonModel, SalonBoxSetting, SalonBoxSettingUpdateModel, SalonInListModel, SalonModel } from "../../shared/models/salon.model";

@Injectable({ providedIn: 'root' })
export class SalonService {

    private restApi: string;
    constructor(
        private http: HttpClient,
        @Inject('SALON_URL') private salonUrl: string
    ) {
        this.restApi = `${salonUrl}salons`;
    }

    filter(request: FilterSalonModel): Observable<FilterSalonModel> {
        if (!request.pageNumber || request.pageNumber <= 0) request.pageNumber = 1;
        if (!request.pageSize || request.pageSize <= 0) request.pageSize = 10;

        let params = new HttpParams();
        for (let key in request) {
            if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
              params = params.append(key, request[key]);
            }
            else{
              if (Array.isArray(request[key])) {
                request[key].forEach(element => {
                  params = params.append(key, element);
                });
              }
            }
          }
        return this.http.get<FilterSalonModel>(this.restApi, { params: params });
    }

    getDetail(salonId: number): Observable<SalonModel> {
        return this.http.get<SalonModel>(`${this.restApi}/${salonId}`);
    }

    create(request: SalonForCreateModel): Observable<string> {
      return this.http.post<string>(this.restApi, request);
    }

    update(request: SalonForUpdateModel): Observable<string> {
      return this.http.put<string>(`${this.restApi}/${request.id}`, request);
    }

    GetSalonUserBelongs(userId: number): Observable<SalonModel> {
        return this.http.get<SalonModel>(`${this.restApi}/salon-user-belong?userId=${userId}`);
    }

    getSalonWithListUsers(salonId: number): Observable<SalonBoxSetting> {
        return this.http.get<SalonBoxSetting>(`${this.restApi}/salon-user-setting-detail/${salonId}`);
    }

    setDetail(model: SalonBoxSettingUpdateModel) {
        return this.http.post(`${this.restApi}/${model.salonId}/set-users`, model.salonUsers);
    }
}
