import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PromotionInBoxCreateModel, PromotionInBoxModel } from "../../shared/models/promotioninbox.model";

@Injectable({
    providedIn: 'root'
})
export class PromotionInBoxService {
    private restApi: string;
    constructor(
        private http: HttpClient, 
        @Inject('PROMOTION_URL') promotionUrl: string) {
        this.restApi = `${promotionUrl}promotion-in-box`;
    }

    filter(promotionBoxId: number, objectId: number): Observable<PromotionInBoxModel[]> {
        return this.http.get<PromotionInBoxModel[]>(`${this.restApi}/${promotionBoxId}/${objectId}/promotions`);
    }

    create(request: PromotionInBoxCreateModel): Observable<string> {
        return this.http.post<string>(this.restApi, request);
    }
}
