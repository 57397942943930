import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { DirectoryModel, DirectoryUpdateModel, EventHandleModel, FileModel, PostFileModel, UploadConfig } from "../../shared/models/fileinfo.model";

@Injectable({ providedIn: 'root' })
export class FileManagerService {

    private apiUrl: string;
    private folderApiUrl: string;
    private fileApiUrl: string;
    private settingApiUrl: string;

    public eventHandle: BehaviorSubject<EventHandleModel> = new BehaviorSubject(new EventHandleModel());

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        @Inject('COMMON_URL') commonUrl: string) {
        this.apiUrl = `${commonUrl}api/upload/token`;
        this.folderApiUrl = `${commonUrl}filemanager/directories`;
        this.fileApiUrl = `${commonUrl}filemanager/files`;
        this.settingApiUrl = `${commonUrl}sitemanagers/storage-setting/token-upload`;
    }

    getUploadSettings(directory: string, callback: any) {
        var now = new Date();
        //if (directory == null || directory == '') directory =  this.authenticationService.getCurrentUser().username;
        var siteSetting = this.authenticationService.getSettingSite();
        var handleApiUrl = directory != '' ? `${this.settingApiUrl}/${siteSetting.id}?folderPath=${directory}` : `${this.settingApiUrl}/${siteSetting.id}`;
        this.http.get<UploadConfig>(handleApiUrl).pipe().subscribe(res => {
            callback(res);
            return res;
        });
    }

    getDirectories(directoryId: number): Observable<DirectoryModel[]> {
        return this.http.get<DirectoryModel[]>(`${this.folderApiUrl}?Id=${directoryId}`);
    }

    getFiles(directoryId: number): Observable<FileModel[]> {
        return this.http.get<FileModel[]>(`${this.fileApiUrl}?DirectoryId=${directoryId}`);
    }

    getToken(folder?: string): Observable<UploadConfig> {
        return this.http.get<UploadConfig>(this.apiUrl + '?folder=' + (folder ? folder : 'temp'));
    }

    uploadFile(fileToUpload: File, uploadConfig: UploadConfig): Observable<any> {
        const endpoint = `${uploadConfig.serverHost}/UploadHandler.php`;
        const formData: FormData = new FormData();
        const headers: any = null;
        formData.append('fileToUpload', fileToUpload, fileToUpload.name);
        formData.append('project', uploadConfig.projectName);
        formData.append('StringDecypt', uploadConfig.token);
        formData.append('UploadType', 'upload');
        formData.append('EncryptType', '128');
        formData.append('IsLarge', 'true');
        formData.append('submit', 'Upload Image');
        return this.http.post<any>(endpoint, formData, { headers: headers });
    }

    saveFile(postFileModels: PostFileModel[]): Observable<any> {
        return this.http.post<any>(`${this.fileApiUrl}`, postFileModels);
    }

    makeDirectory(directoryName: string, path: string) {

    }

    createDirectory(model: DirectoryUpdateModel){
        return this.http.post(this.folderApiUrl, model);
    }

    updateDirectory(model: DirectoryUpdateModel){
        return this.http.put(this.folderApiUrl, model);
    }
    
    deleteDirectory(id: number){
        return this.http.delete(`${this.folderApiUrl}/${id}`);
    }
}
