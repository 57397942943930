import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PromotionBoxModel } from "../../shared/models/promotioninbox.model";

@Injectable({
    providedIn: 'root'
})
export class PromotionBoxService {
    private restApi: string;
    constructor(
        private http: HttpClient, 
        @Inject('PROMOTION_URL') promotionUrl: string
    ) {
        this.restApi = `${promotionUrl}promotion-box`;
    }

    filter(): Observable<PromotionBoxModel[]> {
        return this.http.get<PromotionBoxModel[]>(this.restApi);
    }
}
