import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CityInListModel } from '../../shared/models/city.model';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('COMMON_URL') commonUrl: string
  ) 
  {
      this.restApi = `${commonUrl}cities`;
  }

  getAll(regionId: Number = 0): Observable<CityInListModel[]> {
    return this.http.get<CityInListModel[]>(`${this.restApi}/region/${regionId}`);
  }
}
