// export enum Role {
//     Admin = 1,
//     Editor = 2,
//     Viewer = 3
// }

export enum PermissionGrant {
    Default = 0,
    //VariantManagement = 1,
    ViewListVariant = 2,
    AddVariant = 3,
    ViewVariantDetail = 4,
    UpdateVariant = 5,
    //CarInfoManagement = 6
    ViewListCarInfo = 7,
    AddCarInfo = 8,
    ViewCarInfoDetail = 9,
    UpdateCarInfo = 10,
    //SegmentManagement = 11,
    ViewListSegment = 12,
    AddSegment = 13,
    ViewSegmentDetail = 14,
    UpdateSegment = 15,
    //CarSpecificationManagement = 16,
    ConfigSpecStructure = 17,
    SetCarSpec = 18,
    //NewCarArticleManagement = 19,
    ViewListNewCarArticle = 20,
    AddNewCarArticle = 21,
    ViewNewCarArticleDetail = 22,
    UpdateNewCarArticle = 23,
    SetNewCarArticleInBox = 24,
    //NewCarPageManagement = 25,
    ViewListNewCarPage = 26,
    AddNewCarPage = 27,
    ViewNewCarPageDetail = 28,
    UpdateNewCarPage = 29,
    //PromotionManagement = 30,
    ViewListPromotion = 31,
    AddPromotion = 32,
    ViewPromotionDetail = 33,
    UpdatePromotion = 34,
    SetPromotionInBox = 35,
    //FinancePromotionManagement = 36,
    ViewListFinancePromotion = 37,
    AddFinancePromotion = 38,
    ViewFinancePromotionDetail = 39,
    UpdateFinancePromotion = 40,
    //SalonManagement = 41,
    ViewListSalon = 42,
    SetSalonUsers = 43,
    AddSalon = 51,
    UpdateSalon = 52,
    SetSalonInBox = 53,
    //ColorManagement = 44,
    ViewListColor = 45,
    AddColor = 46,
    UpdateColor = 47,
    //SystemUserManagement = 48,
    ViewListSystemUser = 49,
    SetSystemUserPermission = 50
}
