import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BlockContentType } from '../enums/block-content-type.enum';
import { CarImageType } from '../enums/new-car-image-type.enum';
import { NewCarStatus } from '../enums/new-car-status.enum';
import { NewCarType } from '../enums/new-car-type.enum';
import { StringHelper } from '../../helper';
import { NewCarDetailModel, NewCarInListModel, NewCarListFilter, NewCarUpdateModel } from '../../shared/models/new-car.model';

@Injectable({
  providedIn: 'root'
})
export class NewCarService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('NEWCARS_URL') private newCarUrl: string
  ) {
    this.restApi = `${this.newCarUrl}newcar-article`;
  }

  getList(filter: NewCarListFilter): Observable<NewCarListFilter> {
    let params = new HttpParams();
    for (let key in filter) {
      if (filter.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(filter[key]) && (typeof filter[key] != 'object')) {
        params = params.append(key, filter[key]);
      }
      else{
        if (Array.isArray(filter[key])) {
          filter[key].forEach(element => {
            params = params.append(key, element);
          });
        }
      }
    }
    return this.http.get<NewCarListFilter>(`${this.restApi}`, {params:  params});
  }
  
  getDetail(model: {type: NewCarType, id: number|string} ) {
    let params = new HttpParams();
    params = params.append('Type', `${model.type}`);
    return this.http.get<NewCarDetailModel>(`${this.restApi}/${model.id}`, {params:  params});
  }

  create(model: NewCarUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: NewCarUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }

  checkExist(model: {type: NewCarType, objectId: any}): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('ObjectId', `${model.objectId}`);
    params = params.append('Type', `${model.type}`);
    return this.http.get<boolean>(`${this.restApi}/check-exist`, {params:  params});
  }
}
