import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlertifyService, FileManagerService } from '../../../../core/services';
import { DirectoryUpdateModel, PrivacyEnum } from '../../../../shared/models/fileinfo.model';
import { FormValidationOptions } from '../../../../shared/models/form.model';
import { finalize } from 'rxjs/operators';
import { CustomHttpErrorResponse } from '../../../../shared/models/responsedata';
import { FormHelper } from '../../../../helper';

@Component({
  selector: 'storage-update-folder',
  templateUrl: './update-folder.component.html',
  styleUrls: ['./update-folder.component.scss']
})
export class UpdateFolderComponent implements OnInit, OnChanges {
  form: FormGroup;
  formValidationOptions: FormValidationOptions = new FormValidationOptions();
  listError: string [] = [];
  loading: boolean = false;
  modalRef: BsModalRef;

  @Input('directoryToUpdate') directoryToUpdate: DirectoryUpdateModel;
  @Output('saveResult') saveResult: EventEmitter<DirectoryUpdateModel> = new EventEmitter<DirectoryUpdateModel>();
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter<any>();

  // Getter, setters
  get FormHelper(){
    return FormHelper;
  }

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private alertifyService: AlertifyService,
    private spinner: NgxSpinnerService,
    private filemanagerService: FileManagerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['directoryToUpdate']){
      this.initForm();
    }
  }

  ngOnInit(): void {
    //this.initForm();
  }

  initForm(){
    this.form = this.fb.group({
        id: [this.directoryToUpdate.id],
        name: [this.directoryToUpdate.name, Validators.required],
        parentId: [this.directoryToUpdate.parentId],
        isPublish: [this.directoryToUpdate.privacy == PrivacyEnum.Public ? true : false]
    });
  }

  saveFolder(){
    this.formValidationOptions.isFormSubmitted = true;
    if (this.form.invalid) {
        this.listError = ['Invalid form'];
        return;
    }
    this.loading = true;

    //Rebuild data trước khi gửi lên server
    let updateModel = new DirectoryUpdateModel();
    updateModel = this.form.getRawValue();
    updateModel.privacy = this.form.get('isPublish').value == true ? PrivacyEnum.Public : PrivacyEnum.Private;

    let action$ = updateModel.id > 0? this.filemanagerService.updateDirectory(updateModel) : this.filemanagerService.createDirectory(updateModel);
    this.spinner.show();
    action$
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinner.hide();
        })
      ).subscribe(res => {
        this.toastr.success(updateModel.id > 0 ? this.translate.instant('Common.UpdateSuccess') : this.translate.instant('Common.CreateSuccess'));
        this.saveResult.emit(res as DirectoryUpdateModel);
      },
      err => {
        let customErr = new CustomHttpErrorResponse(err);
        if(customErr.hasAPICustomError){
          this.listError = customErr.apiCustomError;
        }
        else{
          let msg = `<div class="text-muted text-uppercase font-weight-bold font-xs">${this.translate.instant('Common.UnexpectedError')}</div>`;
          if(customErr.hasTradeId)
          {
              msg += `<div class="text-muted font-weight-bold font-xs">Trade id: ${customErr.tradeId}</div>`
          }
          this.alertifyService.errorPopup(msg, null);
        }
      });
  }
}
