import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../helper';
import { SegmentsDetailModel, FilterSegmentsModel, SegmentsModel, SegmentsUpdateModel } from '../../shared/models/segments.model';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  restApi: string;
  constructor(
    private http: HttpClient,
    @Inject('CARINFO_URL') private carInfoUrl: string
  )
  {
    this.restApi = `${carInfoUrl}segments`;
  }

  filter(request: FilterSegmentsModel): Observable<FilterSegmentsModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<FilterSegmentsModel>(`${this.restApi}/filter`, { params: parameters });
  }

  create(model: SegmentsUpdateModel) {
    return this.http.post(this.restApi, model);
  }

  getDetail(id: number|string ) {
    return this.http.get<SegmentsDetailModel>(`${this.restApi}/${id}`);
  }

  update(model: SegmentsUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }
}
