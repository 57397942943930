import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

  export const authCodeFlowConfig: AuthConfig = {
    issuer: environment.authorityDomain,
    redirectUri: `${environment.root}`,
    clientId: 'DVG.AutoPortal.CMS.Spa',
    postLogoutRedirectUri: `${environment.root}`,
    responseType: 'code',
    scope: 'openid profile offline_access permission DVG.AutoPortal.CMS.CarInfo DVG.AutoPortal.CMS.Common DVG.AutoPortal.CMS.Promotion DVG.AutoPortal.CMS.Product',
    // scope: 'openid profile offline_access permission DVG.AutoPortal.CMS.CarInfo DVG.AutoPortal.CMS.Common DVG.AutoPortal.CMS.Promotion DVG.AutoPortal.CMS.Product',
    timeoutFactor: 0.75, //quy định thời gian accesstoken sẽ đc refresf, 0.75 đồng nghĩa đến 3/4 liftetime của token, token sẽ đc refresh (scale từ 0 -1)
    requireHttps: false,
    strictDiscoveryDocumentValidation: false,
    // sessionChecksEnabled: true,
    showDebugInformation: true,
  };
