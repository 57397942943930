export enum PromotionExpiredEnum {
    // All = -1,
    Unexpired = 1,
    Expired = 2
}

export enum PromotionHotInBox {
    HomePage = 1,
    BrandPage = 2,
    ModelPage = 3,
    VariantPage = 4
}

export enum PromotionStatus{
    Active = 1,
    Inactive = 2,
    Disapproved = 3
}

export enum PromotionCondition{
    // Not Required
    Default = 1,
    // Brand Required
    Brand = 2,
    // Model Required
    Model = 3,
    // Variant Required
    Variant = 4
}