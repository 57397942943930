import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { BehaviorSubject, from, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { IS4AuthenticationResponse } from "../../shared/models/external-login.model";
import { AuthenticationService } from "../services";
import { OIDCAuthService } from "./oidcauth.service";

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private oauthService: OAuthService,
        private authStorage: OAuthStorage,
        private authenticationService: AuthenticationService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url.toLowerCase();
        if(this.checkSendBearerToken(url))
        {
            let token = this.oauthService.getAccessToken();
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            const authReq = req.clone({ headers });
            

            return next.handle(authReq)
                .pipe(
                    catchError((err, caught) => {
                        if (err instanceof HttpErrorResponse && err.status === 401) {
                            return this.handle401Error(authReq, next);
                        } 
                        else{
                            return throwError(err);
                        }
                    })
                );
        }
        else{
            return next.handle(req);
        }
    }

    checkSendBearerToken(url: string): boolean {
        let allowDomains: string[] = [];
        if(environment.development){
            allowDomains.push('https://localhost');
        }
        else{
            allowDomains.push(environment.base.apiGateWay);
        }
        let found = allowDomains.find(u => url.startsWith(u));
        return !!found;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            var refreshToken = this.oauthService.getRefreshToken();
            //Explicit refresh token trong TH login bằng external tolen
            return this.authenticationService.refeshTokenForExternalLogin(refreshToken)
                .pipe(
                    switchMap((res: any) => {
                        this.isRefreshing = false;
                        if(res != null || res != undefined)
                        {
                            this.refreshTokenSubject.next(res);
                        }
                        else{
                            this.refreshTokenSubject.next(null);
                        }
                        return next.handle(this.addAuthToken(request));
                    }),
                    catchError((err) => {
                        this.refreshTokenSubject.next(null);
                      // handle e and return a safe value or re-throw
                      this.oauthService.revokeTokenAndLogout();
                      return throwError(err);
                    })
                )
            //Inplicit refresh token nếu login bằng account CMS(hiện tại ko cần vì tự động refresh token sau 3/4 lifetime của token)
    
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(data => {
                    return next.handle(this.addAuthToken(request));
                }),
                catchError((err) => {
                  // handle e and return a safe value or re-throw
                  this.oauthService.revokeTokenAndLogout();
                  return throwError(err);
                }));
        }
    }

    private addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
        let url = request.url.toLowerCase();
        if(this.checkSendBearerToken(url))
        {
            let token = this.oauthService.getAccessToken();
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            const authReq = request.clone({ headers });
            return authReq;
        }
        else{
            return request;
        }
      }
}
