import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActiveStatus } from '../enums/active-status.enum';
import { StringHelper } from '../../helper';
import { CarPropertyComboboxDetailModel, CarPropertyComboboxFilterModel, CarPropertyComboboxListModel, CarPropertyComboboxModel, CarPropertyComboboxUpdateModel } from '../../shared/models/car-property-combobox.model';

@Injectable({
  providedIn: 'root'
})
export class CarPropertyComboboxService {
  private restApi: string;
  constructor(private http: HttpClient, @Inject('CARINFO_URL') carInfoUrl: string) {
      this.restApi = `${carInfoUrl}car-property-comboboxes`;
  }

  filter(request: CarPropertyComboboxFilterModel): Observable<CarPropertyComboboxFilterModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<CarPropertyComboboxFilterModel>(`${this.restApi}/filter`, { params: parameters });
  }

  //Lấy ra danh sách các combobox được sử dụng trong car spec structure
  getListUsingBySpecStructure() : Observable<CarPropertyComboboxDetailModel[]> {
    return this.http.get<CarPropertyComboboxDetailModel[]>(`${this.restApi}/using-by-car-spec`); 
  }

  getAll(): Observable<CarPropertyComboboxModel[]> {
    return this.http.get<CarPropertyComboboxModel[]>(`${this.restApi}`);
  }

  getDetail(id: number) {
    return this.http.get<CarPropertyComboboxDetailModel>(`${this.restApi}/${id}`);
  }

  create(model: CarPropertyComboboxUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: CarPropertyComboboxUpdateModel) {
    return this.http.put(`${this.restApi}/${model.id}`, model);
  }

  delete(id: number){
    return this.http.delete(`${this.restApi}/${id}`);
  }
}
