import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StringHelper } from '../../helper';
import { CarSpecDetailModel, CarSpecFilterModel, CarSpecInListModel, CarSpecUpdateModel } from '../../shared/models/car-spec.model';

@Injectable({
  providedIn: 'root'
})
export class CarSpecService {
  private restApi: string;
  constructor(private http: HttpClient, @Inject('CARINFO_URL') carInfoUrl: string) {
      this.restApi = `${carInfoUrl}car-specs`;
  }

  filter(request: CarSpecFilterModel): Observable<CarSpecFilterModel> {
    let parameters = new HttpParams();
    for (let key in request) {
        if (request.hasOwnProperty(key) && !StringHelper.isNullOrEmpty(request[key]) && (typeof request[key] != 'object')) {
            let value = request[key];
            parameters = parameters.set(key, value);
        }
    }
    return this.http.get<CarSpecFilterModel>(`${this.restApi}/filter`, { params: parameters });
  }

  getCarInfoPropertyValues(carInfoId: String) : Observable<CarSpecDetailModel> {
    // let params = new HttpParams();
    // params = params.append('year', year.toString());
    return this.http.get<CarSpecDetailModel>(`${this.restApi}/${carInfoId}`);
  }

  create(model: CarSpecUpdateModel) {
    return this.http.post(this.restApi, model);
  }
  
  update(model: CarSpecUpdateModel) {
    return this.http.put(this.restApi, model);
  }

  delete(carInfoId: string){
    // let params = new HttpParams();
    // params = params.append('year', year.toString());
    return this.http.delete(`${this.restApi}/${carInfoId}`)
  }
}
