import { ActiveStatus } from '../enums/active-status.enum';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { CategoryInListModel, CategoryRequestUpdateModel, CategoryDetailModel, CategoryTreeListItem } from "../../shared/models/category.model";
import { ResponseData } from '../../shared/models/responsedata';
import { CategorySort } from '../enums/category.enum';
import { OrderDirection } from '../enums/order-direction.enum';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    private newsApi: string;

    restApi: string;
    apiGetList: string;
    apiGetAll: string;

    constructor(
        private http: HttpClient,
        @Inject('COMMON_URL') commonUrl: string
    ) {
        this.restApi = `${commonUrl}categories`;
    }
    // getList(keyword: string): Observable<NewsListModel> {
    //     let apiUrl = this.apiGetList.replace(":pageIndex", String(pageIndex)).replace(":pageSize", String(pageSize));
    //     if (keyword != '') apiUrl = `${apiUrl}&keyword=${keyword}`;
    //     if (categoryId >= 0) apiUrl = `${apiUrl}&categoryId=${categoryId}`;
    //     if (status > 0) apiUrl = `${apiUrl}&status=${status}`;
    //     else apiUrl = `${apiUrl}&status=-1`;
    //     return this.http.get<NewsListModel>(apiUrl);
    // }
    // getAll(): Observable<CategoryInListModel[]> {
    //     return this.http.get<CategoryInListModel[]>(this.apiGetAll);
    // }

    getList(keyword: string = '', parentId: number = 0, shortBy: CategorySort = CategorySort.Name, orderBy: OrderDirection = OrderDirection.Asc): Observable<CategoryTreeListItem[]> {
        let queryParams: string = null;
        if (keyword != '') queryParams = !!queryParams ? `${queryParams}&Keyword=${keyword}` : `?Keyword=${keyword}`;
        if (parentId >= 0) queryParams = !!queryParams ? `${queryParams}&ParentId=${parentId}` : `?ParentId=${parentId}`;
        queryParams = !!queryParams ? `${queryParams}&SortBy=${shortBy}` : `?SortBy=${shortBy}`;
        queryParams = !!queryParams ? `${queryParams}&OrderBy=${orderBy}` : `?OrderBy=${orderBy}`;

        return this.http.get<CategoryTreeListItem[]>(`${this.restApi}${queryParams}`);
    }
    getDetail(id: number) {
        return this.http.get<CategoryDetailModel>(`${this.restApi}/${id}`);
    }

    create(model: CategoryRequestUpdateModel) {
        return this.http.post(this.restApi, model);
    }

    update(model: CategoryRequestUpdateModel) {
        return this.http.put(this.restApi, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.restApi}?categoryId=${id}`);
    }

    changePriority(model: { id: number, priority: number }) {
        return this.http.put(`${this.restApi}/order`, model);
    }
}