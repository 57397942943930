<div class="modal-header" *ngIf="!!directoryToUpdate">
    <h4 class="modal-title pull-left">{{ ( directoryToUpdate.id > 0 ? 'FileManagementModule.UpdateFolder' : 'FileManagementModule.AddFolder') | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" class="form-horizontal" (ngSubmit)="saveFolder()">
    <div class="modal-body">
        <div class="form-group">
            <label for="name">{{ 'Common.Name' | translate }}</label><span class="red"> *</span>
            <input 
                type="text" 
                name="name" 
                formControlName="name" 
                class="form-control"
                [class]="{'is-invalid': FormHelper.required('name', form, formValidationOptions)}"
                placeholder="{{ 'Common.Name' | translate}}"
                maxlength="100"
                autocomplete="off"
                trim="blur">
            <div *ngIf="FormHelper.required('name', form, formValidationOptions)" class="invalid-feedback">
                <span for="name">{{ 'Common.Validation.NameRequired' | translate }}</span>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check form-check-inline big-checkbox" style="width: 100%">
                <input type="checkbox" name="isPublish" class="form-check-input" formControlName="isPublish">
                <label for="isPublish" class="form-check-label" >Publish folder</label>
            </div>
        </div>
        <!-- <div class="form-group">
            <div class="col-md-12">
                <app-form-submit-error 
                    [errors]="listError"
                    id="formMsg"
                    class="formMsg">
                </app-form-submit-error>
            </div>
        </div> -->
    </div>
    <div class="modal-footer">
        <input type="hidden" formControlName="id" />
        <input type="hidden" formControlName="parentId" />
        <button 
            id="btnSave"
            class="btn btn-primary" 
            type="submit">
            {{ 'Common.Save' | translate }}
        </button>
        <button 
            class="btn btn-light float-right" 
            (click)="closeModal.emit(null)" 
            type="button">
            {{ 'Common.Cancel' | translate }}
        </button>
    </div>
</form>