import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enumeration } from '../../shared/models/enumeration.model';

@Injectable({
  providedIn: 'root'
})
export class EnumerationService {
  private restApi: string;
  constructor(private http: HttpClient, @Inject('COMMON_URL') commonUrl: string) {
      this.restApi = `${commonUrl}enumeration`;
  }

  getListObjectType(): Observable<Enumeration[]> {
    return this.http.get<Enumeration[]>(`${this.restApi}/object-type`);
  }
}
